<template>
  <div
    class="container-fluid py-0 page-wrap d-flex justify-content-center h-100"
  >
    <div class="scan-wrap">
      <div class="form-group mb-0">
        <label>Cafe24 주문번호</label>
        <div class="input-group mb-0">
          <input
            v-model="orderNo"
            type="text"
            class="form-control"
            placeholder=""
            aria-label=""
          />
        </div>
      </div>
      <button
        type="button"
        class="btn btn-primary w-100 mb-0"
        @click="sendUsimVoucher()"
      >
        바우처보내기
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "UsimVoucher",
  components: {},
  setup() {},
  data() {
    return {
      orderNo: "",
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    sendUsimVoucher() {
      const param = {
        orderNo: this.orderNo,
      };
      this.$axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "api/test/sendUsimVoucher",
          param
        )
        .then((response) => {
          console.log(response);
        });
    },
  },
};
</script>
<style lang="scss">
body {
  font-family: "Pretendard";
}
.swal2-container {
  font-family: "Pretendard" !important;
  font-weight: normal !important;
  h6 {
    font-weight: normal !important;
  }
}
#app {
  height: 100%;
}
</style>
<style lang="scss" scoped>
.page-wrap {
  font-family: "Pretendard";
  background: #ffffff;
  padding: 0;
  .scan-wrap {
    max-width: 500px;
    width: 100%;
  }

  .bottom-space {
    height: 80px;
  }
}
</style>
