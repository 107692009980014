<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">파트너 관리</h5>
                <p class="mb-0 text-sm">
                  
                </p>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  
                  <soft-button color="primary" full-width="true" @click="showPartnerModal()">파트너추가</soft-button>
                </div>
              </div>
            </div>
            <div class="">
              
              <form class="row row-cols-lg-auto g-3 align-items-end">
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>파트너명</label>
                    <div class="input-group mb-0">
                      <input v-model="searchName" type="text" class="form-control" placeholder="파트너명" aria-label="파트너명">
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <button type="button" class="btn btn-primary mb-0" @click="getPartnerList()">조회</button>
                  <button type="button" class="btn btn-secondary mb-0 ms-1" @click="searchInit()">
                    <span class="btn-inner--icon"><i class="fas fa-sync-alt"></i></span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <table id="admin-list" class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      순번
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      파트너명
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      전화번호
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      수정
                    </th>
                  </tr>
                </thead>
                <tbody >
                  <tr v-for="(item, i) in partnerList" :key="item">
                    <td class="text-center">
                      {{i+1}}
                    </td>
                    <td class="text-left">
                      {{item.name}}
                    </td>
                    <td class="text-left">
                      {{item.tel}}
                    </td>
                    <td class="align-middle text-left">
                      <button type="button" class="btn btn-primary mb-0" data-type="EDIT" data-index="0" @click="showPartnerModal(true, i)">
                        <span class="btn-inner--icon"><i class="fas fa-edit" data-type="EDIT"  data-index="0" ></i></span>
                      </button>
                      <button type="button" class="btn btn-danger mb-0 ms-1" data-type="DELETE" data-index="0"  @click="deletePartner(item.no)">
                        <span class="btn-inner--icon"><i class="fas fa-trash" data-type="DELETE"  data-index="0" ></i></span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="modal-partner" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal-partner" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="card card-plain">
              <div class="card-header pb-0 text-left">
                <h3 class="font-weight-bolder text-primary text-gradient">{{modalTitle}}</h3>
              </div>
              <div class="card-body">
                <form role="form text-left"  class="row" @submit.prevent>
                  <div class="col-6">
                    <div class="form-group">
                      <label>파트너명<span class="text-danger">*</span></label>
                      <input v-model="name" type="text" class="form-control" placeholder="파트너명" aria-label="파트너명" :disabled="modalModfy">
                    </div>
                  </div>
                  <div class="col-6">
                    <label>전화번호</label>
                    <div class="input-group mb-3">
                      <input v-model="tel" type="text" class="form-control" placeholder="전화번호"  aria-label="전화번호">
                    </div>
                  </div>
                  <div class="col-6">
                    <label>템플릿명(파트너사용)</label>
                    <div class="input-group mb-3">
                      <input v-model="templateName" type="text" class="form-control" placeholder="템플릿명"  aria-label="템플릿명">
                    </div>
                  </div>
                  <div class="col-12">
                    <label>담당자</label>
                    <div class="mb-3">
                      <div >
                        <soft-button color="info" @click="addManager()">담당자추가</soft-button>
                      </div>
                      <div class="table-responsive">
                        <table id="admin-list" class="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th
                                class="text-secondary text-xxs font-weight-bolder opacity-7"
                              >
                                순번
                              </th>
                              <th
                                class="text-secondary text-xxs font-weight-bolder opacity-7"
                              >
                                담당자명
                              </th>
                              <th
                                class="text-secondary text-xxs font-weight-bolder opacity-7"
                              >
                                연락처
                              </th>
                              <th
                                class="text-secondary text-xxs font-weight-bolder opacity-7"
                              >
                                이메일
                              </th>
                              <th
                                class="text-secondary text-xxs font-weight-bolder opacity-7"
                              >
                                메일수신여부
                              </th>
                              <th
                                class="text-secondary text-xxs font-weight-bolder opacity-7"
                              >
                                삭제
                              </th>
                            </tr>
                          </thead>
                          <tbody >
                            <tr v-for="(item, i) in managerList" :key="item">
                              <td class="text-center">
                                {{i+1}}
                              </td>
                              <td class="text-left">
                                <input v-model="item.name" type="text" class="form-control form-control-sm" placeholder="담당자명"  aria-label="담당자명">
                              </td>
                              <td class="text-left">
                                <input v-model="item.tel" type="text" class="form-control form-control-sm" placeholder="연락처"  aria-label="연락처">
                              </td>
                              <td class="text-left">
                                <input v-model="item.email" type="text" class="form-control form-control-sm" placeholder="이메일"  aria-label="이메일">
                              </td>
                              <td class="text-center" style="">
                                <div class="form-check form-switch" style="display:inline-block;">
                                  <input v-model="item.email_receive" class="form-check-input" type="checkbox">
                                </div>
                              </td>
                              <td class="align-middle text-left">
                                <button type="button" class="btn btn-danger mb-0 ms-1" data-type="DELETE" data-index="0"  @click="deleteManager(i)">
                                  <span class="btn-inner--icon"><i class="fas fa-trash" data-type="DELETE"  data-index="0" ></i></span>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="text-center">
                    <div class="row">
                      <div class="col-sm">
                        <soft-button color="primary" full-width="true" @click="savePartner()">{{btnSaveText}}</soft-button>
                      </div>
                      <div class="col-sm">
                        <soft-button color="secondary"  full-width="true" @click="modalPartner.hide()">취소</soft-button>
                      </div>
                    </div>  
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { DataTable } from "simple-datatables";
import { Modal } from "bootstrap";
import SoftButton from "@/components/SoftButton.vue";

export default {
  name: "PartnerLists",
  components: {
    SoftButton
  },
  data() {
    return {
      searchId: "",
      searchName: "",
      modalModfy: false, // 
      partnerList: [],

      btnSaveText: "추가",
      modalTitle: "파트너추가",
      
      no: 0,
      name: "",
      tel: "",
      templateName: "",
      managerList: [
        {
          id: 1,
          name: "홍길동",
          tel: "010-0121-4584",
          email: "abcd@dkdkdkd.com"
        },
        {
          id: 2,
          name: "이정준",
          tel: "010-0121-6691",
          email: "lukas@ondelab.com"
        },
        
      ],
    }
  },
  computed: {
  },
  mounted() {
    this.getPartnerList()
  },
  methods: {
    searchInit() {
      this.searchName = "" 
    },
    showPartnerModal(modify, index){
      console.debug("showPartnerModal CALL")
      this.initData()

      this.modalModfy = modify;

      if(modify) {
        this.btnSaveText = "수정"
        this.modalTitle = "파트너 정보 수정"

        this.no = this.partnerList[index].no
        this.name = this.partnerList[index].name
        this.tel = this.partnerList[index].tel
        this.templateName = this.partnerList[index].template_name
        this.managerList = this.partnerList[index].manager
      }
      
      this.modalPartner = new Modal(document.getElementById('modal-partner'))
      this.modalPartner.show()
    },
    getPartnerList() {
      // var vm = this;
      const param = {
        "name" : this.searchName
      }
      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/partner/list", param).then((response) => { // 실제 API를 요청한다/
        console.log(response.data.result);

        this.partnerList = response.data.result

        this.partnerList.forEach((item) => {
          item.manager.forEach((mItem) => {
            mItem.email_receive = mItem.email_receive == "1" ? true : false;
          })

        })

      })
    },
    initData() {

      this.btnSaveText = "추가"
      this.modalTitle = "파트너추가"

      this.no = 0
      this.name = ""
      this.tel = ""
      this.templateName = ""
      this.managerList = []
    },  

    addManager() {
      const addManagerObject = {
        name: "",
        tel: "",
        email: "",
        email_receive: true,
      }

      this.managerList.push(addManagerObject)
    },  

    deleteManager(idx) {
      this.managerList.splice(idx, 1)
    },

    savePartner() {
      if(this.name === "") {
        alert("파트너명을 입력해주세요.");
        return;
      }

      this.managerList.forEach((item) => {
        item.email_receive = item.email_receive==true?'1':'0'
      })

      const param = {
        no: this.no,
        name: this.name,
        tel: this.tel,
        templateName: this.templateName,
        manager: JSON.stringify(this.managerList)
      }

      const modeUri = this.modalModfy?"update":"insert"
      const modeText = this.modalModfy?"수정":"등록"

      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/partner/"+modeUri, param).then((response) => { // 실제 API를 요청한다/
        console.log(response.data.result);

        alert("파트너 "+modeText+"이 완료되었습니다.");

        this.modalPartner.hide()

        this.getPartnerList()
      })


    },
    deletePartner(no) {

      if(!confirm("선택하신 파트너를 삭제하시겠습니까?")) {
        return;
      }

      const param = {
        no
      }

      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/partner/delete", param).then((response) => { // 실제 API를 요청한다/
        console.log(response.data.result);

        alert("파트너 삭제가 완료되었습니다.");

        this.getPartnerList()
      })


    },


  },
};
</script>
