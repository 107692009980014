/**
=========================================================
* Vue Soft UI Dashboard PRO - v3.0.0
=========================================================
* Product Page: https://creative-tim.com/product/vue-soft-ui-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import "./assets/css/fonts.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import SoftUIDashboard from "./soft-ui-dashboard";
// import VueQuagga from 'vue-quaggajs';
// import axios from "axios1ㅍ프로";
import axios from "./plugin/axios";

import VueCookies from "vue3-cookies";

import { globalCookiesConfig } from "vue3-cookies";

import VueDatepickerUi from "vue-datepicker-ui";
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import { createMetaManager, defaultConfig } from "vue-meta";

import "sweetalert2/dist/sweetalert2.min.css";

//createApp setting
const metaManager = createMetaManager(false, {
  ...defaultConfig,
  ...{
    "theme-color": {
      tag: "meta",
      keyAttribute: "name",
      valueAttribute: "content",
    },
    viewport: {
      tag: "meta",
      keyAttribute: "name",
      valueAttribute: "content",
    },
  },
});

globalCookiesConfig({
  expireTimes: "30d",
  path: "/",
  domain: "",
  secure: true,
  sameSite: "None",
});

const appInstance = createApp(App);
appInstance.use(VueCookies, {
  expireTimes: "30d",
  path: "/",
  domain: "",
  secure: true,
  sameSite: "None",
});
appInstance.use(store);
appInstance.use(router);
appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);
appInstance.use(SoftUIDashboard);
appInstance.use(store);
appInstance.use(metaManager);
appInstance.use(VueSweetalert2);
// appInstance.use(VueQuagga);
appInstance.component("Datepicker", VueDatepickerUi);
appInstance.config.globalProperties.$axios = axios;
appInstance.mount("#app");
