<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">GetYourGuide 주문 현황</h5>
                <p class="mb-0 text-sm">
                  
                </p>
              </div>
            </div>
            <div class="">
              
              <form class="row row-cols-lg-auto g-3 align-items-end">
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>Booking ID</label>
                    <div class="input-group mb-0">
                      <input v-model="searchGygBookingReference" type="text" class="form-control" placeholder="주문번호" aria-label="주문번호">
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>주문자</label>
                    <div class="input-group mb-0">
                      <input v-model="searchTravelersName" type="text" class="form-control" placeholder="주문자 성명" aria-label="주문자 성명">
                    </div>
                  </div>
                </div><div class="col-12">
                  <div class="form-group mb-0">
                    <label>Cafe24 상품명</label>
                    <div class="input-group mb-0">
                      <input v-model="searchProductName" type="text" class="form-control" placeholder="Cafe24 상품명" aria-label="Cafe24 상품명">
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>주문일</label>
                    <div class="input-group mb-0">
                      <flat-picker
                        v-model="searchOrderDateRange"
                        :config="{ allowInput: true, mode: 'range', parseDate: true }"
                        class="form-control datepicker"
                        @on-open="focus"
                        @on-close="blur"
                        @on-change="onDateChange"
                      >
                      </flat-picker>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>처리상태</label>
                    <div class="input-group mb-0">
                      <select v-model="searchStatus"  class="form-control">
                        <option value="">전체</option>
                        <option value="R">배송대기</option>
                        <option value="S">배송성공</option>
                        <option value="F">배송실패</option>
                        <option value="C">재고부족</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <button type="button" class="btn btn-primary mb-0" @click="getGygOrderList()">조회</button>
                  <button type="button" class="btn btn-secondary mb-0 ms-1" @click="initSearchData()">
                    <span class="btn-inner--icon"><i class="fas fa-sync-alt"></i></span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div ref="table"></div>
            <!-- <div class="table-responsive">
              <table id="esim-list" class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      주문번호
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      주문자
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      <div>이메일</div>
                      <div>전화번호</div>
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      [상품코드] 상품명
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      <div>주문일시</div>
                      <div>배송일시</div>
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      처리상태
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      재배송
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      메모
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr  v-for="(item, idx) in gygOrderList" :key="item">
                    <td class="text-center">
                      {{item.gyg_booking_reference}}
                    </td>
                    <td class="text-left">
                      {{item.first_name}} {{item.last_name}}
                    </td>
                    <td class="text-left">
                      <div>{{item.email}}</div>
                      <div>{{item.phone_number}}</div>
                    </td>
                    <td class="text-left">
                      [{{item.prod_code}}-{{item.opt_code}}] {{item.prod_name}}
                    </td>
                    <td class="align-middle text-left text-sm">
                      <div>&nbsp;{{getFullDateString(item.date_time)}}</div>
                      <div>&nbsp;{{getFullDateString(item.recent_send_email_at)}}</div>
                    </td>
                    <td class="align-middle text-center">
                      <div v-if="item.status=='F'" class="cursor-hand" :class="getGygOrderStatusClass(item.status)" data-bs-toggle="tooltip" :data-bs-title="item.mail_send_message">{{getGygOrderStatusName(item.status)}}</div>
                      <div v-else-if="item.status=='A'" class="cursor-hand" :class="getGygOrderStatusClass(item.status)" data-bs-toggle="tooltip" :data-bs-title="item.json_desc">
                        {{getGygOrderStatusName(item.status)}}<br>
                        <button type="button" class="btn btn-outline-secondary btn-sm" @click="reOrderAPI(idx)"><i class="fas fa-redo cursor-hand"></i></button>
                      </div>
                      <div v-else :class="getGygOrderStatusClass(item.status)" >{{getGygOrderStatusName(item.status)}}</div>
                    </td>
                    <td class="align-middle text-center">
                      <button type="button" class="btn btn-outline-secondary btn-sm mb-0" :disabled="item.status=='N' || item.status=='C' || item.status=='A'|| item.status=='P'" @click="showEsimOrderInfodModal(item)"><i class="fas fa-envelope cursor-hand" ></i></button>
                    </td>
                    <td class="align-middle text-center">
                      <i class="far fa-sticky-note cursor-hand" @click="showGygEsimOrderCommentModal(item)"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div id="modal-comment" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal-comment" aria-hidden="true" >
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="card card-plain">
              <div class="card-header pb-0 text-left">
                <h3 class="font-weight-bolder text-primary text-gradient">메모 ({{orderId}})</h3>
              </div>
              <div class="card-body">
                <form role="form text-left" @submit.prevent>
                  <div class="form-group">
                    <div class="input-group mb-3">
                      <textarea v-model="memo" type="text" class="form-control" placeholder="" aria-label="" style="resize:none;height:100px;"></textarea>
                      <soft-button color="info" @click="saveMemo()">기록</soft-button>
                    </div>
                  </div>
                </form>
                <div class="memo-wrap">
                  <ul class="list-group">
                    <li v-if="commentList.length == 0" class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg text-center">
                      등록된 메모가 없습니다.
                    </li>
                    <li
                      v-for="(item, index) of commentList"
                      :key="index"
                      class="list-group-item border-0 d-flex p-2 mb-2 bg-gray-100 border-radius-lg"
                    >
                      <div class="d-flex flex-column">
                        <h6 class="mb-3 text-sm">{{ item.memo }}</h6>
                      </div>
                      <div class="ms-auto text-end">
                        <span class="text-secondary h6" style="font-size:0.7rem;">{{ getFullDateString(item.create_at) }}</span>
                      </div>
                    </li>
                  </ul>
                </div>

              </div>
              <div class="card-footer">
                <soft-button color="secondary" full-width="true" @click="modalComment.hide()">닫기</soft-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="modal-esim" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal-esim-info" aria-hidden="true" >
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="card card-plain">
              <div class="card-header pb-0 text-left">
                <h3 class="font-weight-bolder text-primary text-gradient">eSim Information ({{orderId}})</h3>
              </div>
              <div class="card-body">
                <div class="esim-info-wrap">
                  <li v-if="esimList.length == 0" class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg text-center">
                    Not Find Esim Information.
                  </li>
                  <ul class="list-group">
                    <li
                      v-for="(item, index) of esimList"
                      :key="index"
                      class="list-group-item border-0 d-flex p-2 mb-2 bg-gray-100 border-radius-lg"
                    >
                      <div class="w-100 d-block">
                        <div class="mb-1 d-block text-sm esim-info-item"><i class="far fa-copy cursor-hand" @click="copyText(item.act_code)"></i> <span class="fw-bold">ACT_CODE : </span>{{ item.act_code }}</div>
                        <div class="mb-1 d-block text-sm esim-info-item"><i class="far fa-copy cursor-hand" @click="copyText(item.iccid)"></i> <span class="fw-bold">ICCID : </span>{{ item.iccid }}</div>
                        <div class=" d-block text-sm esim-info-item"><i class="far fa-copy cursor-hand" @click="copyText(item.msisdn)"></i> <span class="fw-bold">MSISDN : </span>{{ item.msisdn }}</div>
                      </div>
                    </li>
                  </ul>
                </div>

              </div>
              <div class="card-footer">
                <soft-button color="secondary" full-width="true" @click="modalEsim.hide()">닫기</soft-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>




  </div>
</template>

<script>
// import { DataTable } from "simple-datatables";
import { Modal } from "bootstrap";
import SoftButton from "@/components/SoftButton.vue";

import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";


import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import {TabulatorFull as Tabulator} from 'tabulator-tables'; 

export default {
  name: "GygOrderList",
  components: {
    SoftButton,
    flatPicker,
  },
  data() {
    return {
      searchGygBookingReference: "",
      searchTravelersName: "",
      searchBuyerEmail: "",
      searchTravelersPhoneNumber: "",
      searchProductName: "",
      searchOrderDateRange: null,
      searchOrderDateStart: "",
      searchOrderDateEnd: "",
      searchStatus: "",

      gygOrderList: [],

      commentList: [],
      esimList: [],
      gygBookingReference: "0",
      memo: "",
      editGygEsimOrderItemNo: 0,
      editGygEsimOrderMappingNo: 0,
      editGygEsimOrderTravelersNo: 0,

      // no: 0,
      // prodNo: 0,
      // prodCode: "",
      // prodName: "",
      // simType: "",
      // simNum: "",
      // expireDate: "",
      // actCode: "",
      // status: "",
      // statusName: "",
      // changeStatus: "",

      esimNo: 0,
      name: "",
      orgEmail: "",
      email: "",
      prodCode: "",
      optCode: "",
      iccid: "",

      disabledEsimChange: false,

      esimChange: false,

      tabulator: null, //variable
    }
  },
  computed: {
    getGygOrderStatusName() {
      return (status) => {
        if(status === "R") {
          return "배송대기"
        }
        else if(status === "S") {
          return "성공"
        }
        else if(status === "F") {
          return "메일전송실패"
        }
        else if(status === "A") {
          return "CMI연동실패"
        }
        else if(status === "C") {
          return "재고부족"
        }
        else if(status === "N") {
          return "매창상품없음"
        }
        else if(status === "P") {
          return "API연동대기"
        }
        else if(status === "M") {
          return "이메일전송대기"
        }
      }
    },
    getFullDateString() {
      return (date) =>{
        return date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : " "
      }
    }, 
    getCafe24OrderEmail() {
      return (item) =>{
        if(item.cafe24_order_place_id == "shopn") 
          return item.cafe24_shipping_message
        else
          return item.cafe24_buyer_email
      }
    }, 
    getGygOrderStatusClass() {
      return (status) => {
        if(status=="F" || status=="A" || status=="N" || status=="C" || status=="P") {
          return 'text-danger';
        }
        else if(status=="S") {
          return 'text-success';
        }
        else {
          return '';
        }
      }
    },
  },
  mounted() {
    dayjs.extend(customParseFormat)
    dayjs.extend(utc);
    dayjs.extend(timezone);

    this.tabulator = new Tabulator(this.$refs.table, {
      data: this.productList, //link data to table
      reactiveData:true, //enable data reactivity
      renderHorizontal:"virtual",
      pagination:"local",
      paginationSize:50,
      paginationSizeSelector:[25, 50, 100],
      paginationCounter:"rows",
      columns: [
        { title: "Booking ID", field: "gyg_booking_reference", vertAlign:"middle"},
        { title: "주문자", field: "name", vertAlign:"middle"},
        { title: "[상품코드] 상품명", field: "prod_name", formatter:this.columnFormatterHandeler, formatterParams: "product", vertAlign:"middle"},
        { title: "부킹일시", field: "date_time", formatter:this.columnFormatterHandeler, formatterParams: "date"},
        { title: "수량", field: "count", hozAlign: "center"},
        { title: "상태", field: "status", formatter:this.columnFormatterHandeler, formatterParams: "status"},
        { title: "eSIM정보", field: "status", formatter:this.columnFormatterHandeler, formatterParams: "esim", cellClick: this.esimInfoClickHandeler, headerSort:false, hozAlign:"center"},
        { title: "메모", formatter:this.columnFormatterHandeler, formatterParams: "memo", cellClick: this.memoClickHandeler, vertAlign:"middle", headerSort:false, hozAlign:"center"},
      ], //define table columns
    });

    this.getGygOrderList()

  },
  methods: {
    async copyText(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        alert('복사되었습니다.');
      } catch($e) {
        alert('Error!');
      }
    },
    initSearchData() {
      this.searchGygBookingReference = ""
      this.searchTravelersName = ""
      this.searchTravelersEmail = ""
      this.searchTravelersPhoneNumber = ""
      this.searchProductName = ""
      this.searchOrderDateRange = null
      this.searchStatus = ""
    }, 
    initEsimOrderInfoData() {
      this.editGygEsimOrderItemNo = 0
      this.editGygEsimOrderMappingNo = 0
      this.editGygEsimOrderTravelersNo = 0
      this.esimNo = 0
      this.gygBookingReference = ""
      this.name = ""
      this.orgEmail = ""
      this.email = ""
      this.prodCode = ""
      this.optCode = ""
      this.iccid = ""

      this.disabledEsimChange = false
    },
    getGygOrderList() {

      const aryRangeDate = this.searchOrderDateRange ? this.searchOrderDateRange.split(" to ") : []

      // var vm = this;
      const param = {
        "searchGygBookingReference" : this.searchGygBookingReference,
        "searchTravelersName" : this.searchTravelersName,
        "searchTravelersEmail" : this.searchTravelersEmail,
        "searchTravelersPhoneNumber" : this.searchTravelersPhoneNumber,
        "searchProductName" : this.searchProductName,
        "searchStatus" : this.searchStatus,
      }

      if(aryRangeDate.length == 1) {
        param.searchOrderDateStart = dayjs(this.searchOrderDateRange,"YYYY-MM-DD").startOf("date").format("YYYY-MM-DD HH:mm:ss")
        param.searchOrderDateEnd = dayjs(this.searchOrderDateRange,"YYYY-MM-DD").endOf("date").format("YYYY-MM-DD HH:mm:ss")
      }
      else if(aryRangeDate.length == 2) {
        param.searchOrderDateStart = dayjs(aryRangeDate[0],"YYYY-MM-DD").startOf("date").format("YYYY-MM-DD HH:mm:ss")
        param.searchOrderDateEnd = dayjs(aryRangeDate[1],"YYYY-MM-DD").endOf("date").format("YYYY-MM-DD HH:mm:ss")
      }

      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/gyg_order/list", param).then((response) => { // 실제 API를 요청한다/
        console.log(response.data.result);

        this.gygOrderList = response.data.result;

        this.gygOrderList.forEach((item) => {
          item.name = item.first_name + " " + item.last_name;
        })

        this.tabulator.setData(this.gygOrderList);

      })
    },
    reOrderAPI(idx) {

      if(!confirm("CMI 주문연동을 다시하시겠습니까?")) {
        return;
      }

      const reOrderItem = this.gyg4OrderList[idx]

      // var vm = this;
      const param = {
        "esimOrderMappingNo" : reOrderItem.esim_order_mapping_no,
        "prodCode" : reOrderItem.prod_code,
        "optCode" : reOrderItem.opt_code,
        "esimNo": reOrderItem.esim_no,
      }

      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/cafe24_order/reorder_cmi", param).then((response) => { // 실제 API를 요청한다/
        console.log(response.data);

        const resultOrderCmi = response.data;

        if(resultOrderCmi.statusCode == "0000"){
          alert("CMI 주문요청을 하였습니다.")

          this.getGygOrderList()
        }
        else {
          alert("CMI 주문요청 시 오류가 발생하였습니다.\n"+resultOrderCmi.message);

          this.getGygOrderList()
        }

      })
    },
    resendEmail() {

      if(this.emailAddress == "") {
        alert("이메일 주소를 입력해주세요.");
        return;
      }

      let confirmMessage = "";
      
      if(this.orgEmailAddress != this.emailAddress) {
        confirmMessage = "이메일 주소가 변경되었습니다.\n변경된 이메일로 전송을 다시하시겠습니까?";
      }
      else {
        confirmMessage = "이메일 전송을 다시하시겠습니까?";
      }

      if(!confirm(confirmMessage)) {
        return;
      }

      // var vm = this;
      const param = {
        "gygEsimOrderItemNo" : this.editGygEsimOrderItemNo,
        "gygEsimOrderMappingNo" : this.editGygEsimOrderMappingNo,
        "gygEsimOrderTravelersNo" : this.editGygEsimOrderTravelersNo,
        "orgEmail": this.orgEmail,
        "email": this.email,
      }
      console.log(param)
      
      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/gyg_order/resend", param).then((response) => { // 실제 API를 요청한다/
        console.log(response.data.result);

        const resultResend = response.data;
        
        if(resultResend.statusCode == "0000"){
          alert("이메일전송을 다시 하였습니다.")

          this.modalEsimOrderInfo.hide()

          this.getGygOrderList()
        }
        else {
          alert("이메일전송 시 오류가 발생하였습니다.\n"+resultResend.message);

          this.getGygOrderList()
        }

      })
    },

    changeEsim() {

      if(!confirm("eSIM 변경 작업을 진행하시겠습니까?")) {
        return;
      }

      // var vm = this;
      const param = {
        "gygEsimOrderMappingNo" : this.editGygEsimOrderMappingNo,
        "esimNo": this.esimNo
      }

      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/gyg_order/changeEsim", param).then((response) => { // 실제 API를 요청한다/
        console.log(response.data.result);

        
        if(response.data.statusCode == "0000"){
          const resultChangeEsim = response.data.result[0];

          if(resultChangeEsim.gygEsimOrderMappingStatus == "N") {
            alert("매칭 상품이 없습니다.");
          }
          else if(resultChangeEsim.gygEsimOrderMappingStatus == "C") {
            alert("재고가 부족합니다.");
          }
          else if(resultChangeEsim.gygEsimOrderMappingStatus == "A") {
            alert("eSIM 변경은 완료되었으나 CMI API연동 시 오류가 발생하였습니다.");
          }
          else if(resultChangeEsim.gygEsimOrderMappingStatus == "R") {
            alert("eSIM 변경 작업이 완료되었습니다.");
            this.iccid = resultChangeEsim.iccid;
            this.disabledEsimChange = true
          }


          this.getGygOrderList()
        }
        else {
          alert("eSIM 변경 시 오류가 발생하였습니다.\n"+response.data.message);

          this.getGygOrderList()
        }

      })
    },

    showEsimOrderInfodModal(item) {

      this.initEsimOrderInfoData();

      this.editGygEsimOrderItemNo = item.gyg_esim_order_item_no;
      this.editGygEsimOrderMappingNo = item.gyg_esim_order_mapping_no;
      this.editGygEsimOrderTravelersNo = item.gyg_esim_order_travelers_no;

      this.esimNo = item.esim_no;
      this.gygBookingReference = item.gyg_booking_reference
      this.name = item.first_name + " " + item.last_name;
      this.orgEmail = item.email;
      this.email = item.email;
      this.prodCode = item.prod_code;
      this.optCode = item.opt_code;
      this.iccid = item.iccid;


      this.modalEsimOrderInfo = new Modal(document.getElementById('modal-esim-order-info'))
      
      this.modalEsimOrderInfo.show()

    },  

    showGygEsimOrderCommentModal(item) {

      this.commentList = [];
      this.gygBookingReference = item.gyg_booking_reference;
      this.editGygEsimOrderMappingNo = item.gyg_esim_order_mapping_no;
      this.memo = "";

      this.getGygEsimOrderComment(item.gyg_esim_order_mapping_no);

      

      this.modalComment = new Modal(document.getElementById('modal-comment'))
      this.modalComment.show()


    },

    getGygEsimOrderComment(no) {

      const param = {
        "gygEsimOrderMappingNo" : no,
      }

      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/gyg_order/commentList", param).then((response) => { // 실제 API를 요청한다/
        console.log(response.data.result);

        this.commentList = response.data.result
      })
    },

    showEsimInfoModal(item) {

      this.esimList = [];
      this.gygBookingReference = item.gyg_booking_reference
      
      this.getGygEsimOrderEsimInfo(item.gyg_esim_order_no);



      this.modalEsim = new Modal(document.getElementById('modal-esim'))
      this.modalEsim.show()


    },

    getGygEsimOrderEsimInfo(no) {

      const param = {
        "gygEsimOrderNo" : no,
      }

      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/gyg_order/esimList", param).then((response) => { // 실제 API를 요청한다/
        console.log(response.data.result);

        this.esimList = response.data.result
      })
    },

    

    saveMemo() {
      const param = {
        gygEsimOrderMappingNo : this.editGygEsimOrderMappingNo,
        memo: this.memo,
      }

      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/gyg_order/saveComment", param).then((response) => { // 실제 API를 요청한다/

        this.memo = "";

        const resultMemo = response.data;
        
        if(resultMemo.statusCode == "0000"){
          alert("메모를 등록하였습니다.")

          this.getGygEsimOrderComment(this.editGygEsimOrderMappingNo)
        }
        else {
          alert("메모등록 시 오류가 발생하였습니다.\n"+resultMemo.message);

          this.getGygEsimOrderComment(this.editGygEsimOrderMappingNo)
        }

      })
    },

    columnFormatterHandeler(cell, formatterParams) {
      const content = document.createElement("span");
      const data = cell.getRow().getData();


      
      if(formatterParams == "email") {
        content.innerHTML =  `
          <div>${data.email}</div>
          <div>${data.phone_number}</div>
        `;
      }
      else if(formatterParams == "product") {
        content.innerHTML =  `
          <div>[${data.prod_code}-${data.opt_code}] ${data.prod_name}</div>
        `;
      }
      else if(formatterParams == "date") {
        content.innerHTML =  `
          <div>&nbsp;${this.getFullDateString(data.date_time)}</div>
        `;
      }
      else if(formatterParams == "status") {
        const className = this.getGygOrderStatusClass(data.status);
        const statusName = this.getGygOrderStatusName(data.status);
        let bsTitle = "";
        if(data.status=="F") {
          bsTitle = data.mail_send_message;
          content.innerHTML = `<div class="cursor-hand ${className}" data-bs-toggle="tooltip" data-bs-title="${bsTitle}">${statusName}</div>`;
        }
        else if(data.status=="A") {
          bsTitle = data.json_desc;
          content.innerHTML = `<div class="cursor-hand ${className}" data-bs-toggle="tooltip" data-bs-title="${bsTitle}">${statusName}<br><button type="button" class="btn btn-outline-secondary btn-sm click-class" @click="reOrderAPI(idx)"><i class="fas fa-redo cursor-hand click-class"></i></button></div>`;
        }
        else {
          content.innerHTML = `<div class="cursor-hand ${className}">${statusName}</div>`;
        }
      }
      else if(formatterParams == "resend") {
        if(data.status == "N" || data.status == "C" || data.status == "A" || data.status == "P") {
          content.innerHTML =  `<button type="button" class="btn btn-outline-secondary btn-sm mb-0" disabled="true"><i class="fas fa-envelope cursor-hand" ></i></button>`;  
        }
        else {
          content.innerHTML =  `<button type="button" class="btn btn-outline-secondary btn-sm mb-0  click-class"><i class="fas fa-envelope cursor-hand  click-class" ></i></button>`;  
        }
      }
      else if(formatterParams == "esim") {
        content.innerHTML =  `
          <i class="fas fa-sd-card cursor-hand click-esim-class"></i>
        `;
      }
      else if(formatterParams == "memo") {
        content.innerHTML =  `
          <i class="far fa-sticky-note cursor-hand click-memo-class"></i>
        `;
      }

      return content
    },

    esimInfoClickHandeler(e, cell) {
      const target = e.target;

      if(target.classList.contains("click-esim-class")){
        const data = cell.getRow().getData();

        this.showEsimInfoModal(data)
      }
    },

    memoClickHandeler(e, cell) {
      const target = e.target;

      if(target.classList.contains("click-memo-class")){
        const data = cell.getRow().getData();

        this.showGygEsimOrderCommentModal(data)
      }
    }
    

  },
};
</script>

<style scoped >
  .memo-wrap {
    height: 300px;
    overflow-y:scroll;
  }

  .esim-info-wrap {
    height: 400px;
    overflow-y:scroll;

    .esim-info-item {
      
    }
  }
</style>