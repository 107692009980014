<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">유심스토어 상품코드</h5>
                <p class="mb-0 text-sm"></p>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <soft-button
                    color="primary"
                    :disabled="auth == 2"
                    @click="showProductModal(false)"
                    >+&nbsp; 상품코드 등록</soft-button
                  >
                </div>
              </div>
            </div>
            <div class="">
              <form class="row row-cols-lg-auto g-3 align-items-end">
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>상품유형</label>
                    <select
                      id="admin-auth"
                      v-model="searchItemType"
                      class="form-control form-select"
                    >
                      <option value="">전체</option>
                      <option value="USIM">유심</option>
                      <option value="ESIM">eSIM</option>
                      <option value="WIFI">와이파이</option>
                    </select>
                  </div>
                </div>
                <!-- <div class="col-12">
                  <div class="form-group mb-0">
                    <label>사용플랫폼</label>
                    <div class="input-group mb-0">
                      <VueMultiselect
                        v-model="searchUsePlatform"
                        :options="optionsUsePlatform"
                        :multiple="true"
                        :taggable="true"
                      >
                      </VueMultiselect>
                    </div>
                  </div>
                </div> -->
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>상품코드</label>
                    <div class="input-group mb-0">
                      <input
                        v-model="searchItIdOpt"
                        type="text"
                        class="form-control"
                        placeholder="상품코드"
                        aria-label="상품코드"
                      />
                    </div>
                  </div>
                </div>
                <!-- <div class="col-12">
                  <div class="form-group mb-0">
                    <label>옵션코드</label>
                    <div class="input-group mb-0">
                      <input
                        v-model="searchOptCode"
                        type="text"
                        class="form-control"
                        placeholder="옵션코드"
                        aria-label="옵션코드"
                      />
                    </div>
                  </div>
                </div> -->
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>지역</label>
                    <select
                      id="admin-auth"
                      v-model="searchCaId"
                      class="form-control form-select"
                    >
                      <option value="">전체</option>
                      <option value="미국">미국</option>
                      <option value="유럽">유럽</option>
                      <option value="일본">일본</option>
                      <option value="중화권">중화권</option>
                      <option value="동남아">동남아</option>
                      <option value="한국">한국</option>
                      <option value="아시아 기타">아시아 기타</option>
                    </select>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>공급처</label>
                    <select
                      id="admin-auth"
                      v-model="searchSupplier"
                      class="form-control form-select"
                    >
                      <option value="">전체</option>
                      <option
                        v-for="item of supplierList"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.supplier_name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>상품명</label>
                    <div class="input-group mb-0">
                      <input
                        v-model="searchStName"
                        type="text"
                        class="form-control"
                        placeholder="상품명"
                        aria-label="상품명"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <button
                    type="button"
                    class="btn btn-primary mb-0"
                    @click="getProductList()"
                  >
                    조회
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary mb-0 ms-1"
                    @click="searchInit()"
                  >
                    <span class="btn-inner--icon"
                      ><i class="fas fa-sync-alt"></i
                    ></span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div ref="table"></div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="modal-product"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modal-admin"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="card card-plain">
              <div class="card-header pb-0 text-left">
                <h3 class="font-weight-bolder text-primary text-gradient">
                  {{ modalTitle }}
                </h3>
              </div>
              <div class="card-body">
                <h4>기본정보</h4>
                <form role="form text-left" class="row" @submit.prevent>
                  <div class="col-12">
                    <div v-if="stId != 0" class="text-right">
                      <soft-button
                        class="float-end"
                        color="danger"
                        size="sm"
                        :disabled="auth == 2"
                        @click="deleteProduct()"
                        >삭제</soft-button
                      >
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label>상품유형</label>
                      <select
                        id="item-type"
                        v-model="itemType"
                        class="form-control form-select"
                      >
                        <option value="USIM">유심</option>
                        <option value="ESIM">eSIM</option>
                        <option value="WIFI">와이파이</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-5">
                    <div class="form-group">
                      <label>상품코드</label>
                      <div class="input-group mb-3">
                        <input
                          v-model="itIdOpt"
                          type="text"
                          class="form-control"
                          placeholder="상품코드"
                          aria-label="상품코드"
                          :disabled="modalModfy"
                        />
                        <button
                          v-if="!modalModfy"
                          id="button-addon2"
                          class="btn btn-outline-primary mb-0"
                          type="button"
                          :disabled="btnProdCodeCheckDisabled"
                          @click="checkProdCode()"
                        >
                          중복확인
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>지역</label>
                      <select
                        id="ca-id"
                        v-model="caId"
                        class="form-control form-select"
                      >
                        <option value="미국">미국</option>
                        <option value="유럽">유럽</option>
                        <option value="일본">일본</option>
                        <option value="중화권">중화권</option>
                        <option value="동남아">동남아</option>
                        <option value="한국">한국</option>
                        <option value="아시아 기타">아시아 기타</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="form-group">
                      <label>상품명</label>
                      <div class="input-group mb-3">
                        <input
                          v-model="stName"
                          type="text"
                          class="form-control"
                          placeholder="상품명"
                          aria-label="상품명"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label for="admin-auth">공급처</label>
                      <select
                        id="admin-auth"
                        v-model="supplier"
                        class="form-control form-select"
                      >
                        <option
                          v-for="item of supplierList"
                          :key="item.id"
                          :value="item.id"
                        >
                          {{ item.supplier_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!-- <div class="col-12">
                    <div class="form-group">
                      <label>커버리지</label>
                      <div class="input-group mb-3"></div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>USSD</label>
                      <div class="input-group mb-3"></div>
                    </div>
                  </div> -->
                  <div class="col-6">
                    <div class="form-group">
                      <label for="data-text">데이터</label>
                      <input
                        id="data-text"
                        v-model="dataText"
                        type="text"
                        class="form-control"
                        placeholder="데이터"
                        aria-label="데이터"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="call-text">통화/문자</label>
                      <input
                        id="call-text"
                        v-model="callText"
                        type="text"
                        class="form-control"
                        placeholder="통화/문자"
                        aria-label="통화/문자"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>핫스팟</label>
                      <div class="input-group mb-0">
                        <span class="input-group-text">
                          <div class="form-check form-switch">
                            <input
                              id="hotspot-at"
                              v-model="hotspotAt"
                              class="form-check-input"
                              type="checkbox"
                            /></div
                        ></span>
                        <input
                          id="hotspot-desc"
                          v-model="hotspotDesc"
                          :disabled="!hotspotAt"
                          type="text"
                          class="form-control"
                          placeholder=""
                          aria-label=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="apn-text">APN</label>
                      <input
                        id="apn-text"
                        v-model="apnText"
                        type="text"
                        class="form-control"
                        placeholder="APN"
                        aria-label="APN"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="roaming-at">로밍여부</label>
                      <div class="form-check form-switch">
                        <input
                          id="roaming-at"
                          v-model="roamingAt"
                          class="form-check-input"
                          type="checkbox"
                        />
                      </div>
                    </div>
                  </div>
                </form>
                <h4 v-if="itemType == 'ESIM'">eSIM 상품정보</h4>
                <form
                  v-if="itemType == 'ESIM'"
                  role="form text-left"
                  class="row"
                  @submit.prevent
                >
                  <div class="col-6">
                    <div class="form-group">
                      <label for="esim-type">eSIM 유형</label>
                      <select
                        id="esim-type"
                        v-model="esimType"
                        class="form-control form-select"
                      >
                        <option value=""></option>
                        <option value="LPA">LPA</option>
                        <option value="REDEEM">REDEEM</option>
                        <option value="DISCOVERY">DISCOVERY</option>
                      </select>
                    </div>
                  </div>
                  <div v-if="esimType == 'DISCOVERY'" class="col-6">
                    <div class="form-group">
                      <label for="default-qrcode">기본 QRCODE</label>
                      <input
                        id="default-qrcode"
                        v-model="defaultQrcode"
                        type="text"
                        class="form-control"
                        placeholder="기본 LPA URI(QR 코드) 입력"
                        aria-label="기본 LPA URI(QR 코드) 입력"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="main-title">주상품명</label>
                      <div class="input-group mb-3">
                        <input
                          id="main-title"
                          v-model="mainTitle"
                          type="text"
                          class="form-control"
                          placeholder="주상품명"
                          aria-label="주상품명"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="sub-title">보조상품명</label>
                      <div class="input-group mb-3">
                        <input
                          id="sub-title"
                          v-model="subTitle"
                          type="text"
                          class="form-control"
                          placeholder="보조상품명"
                          aria-label="보조상품명"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="is-fup">정보수집</label>
                      <div class="input-group mb-3">
                        <div class="form-check form-switch me-4">
                          <label class="form-check-label" for="collect-date-at"
                            >개통희망일</label
                          >
                          <input
                            id="collect-date-at"
                            v-model="collectDateAt"
                            class="form-check-input"
                            type="checkbox"
                          />
                        </div>
                        <div class="form-check form-switch me-4">
                          <label class="form-check-label" for="collect-imei-at"
                            >IMEI</label
                          >
                          <input
                            id="collect-imei-at"
                            v-model="collectImeiAt"
                            class="form-check-input"
                            type="checkbox"
                          />
                        </div>
                        <div class="form-check form-switch me-4">
                          <label class="form-check-label" for="collect-eid-at"
                            >EID</label
                          >
                          <input
                            id="collect-eid-at"
                            v-model="collectEidAt"
                            class="form-check-input"
                            type="checkbox"
                          />
                        </div>
                        <div class="form-check form-switch">
                          <label
                            class="form-check-label"
                            for="collect-passport-at"
                            >여권정보</label
                          >
                          <input
                            id="collect-passport-at"
                            v-model="collectPassportAt"
                            class="form-check-input"
                            type="checkbox"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>개통시점</label>
                      <select
                        id="open-point"
                        v-model="openPoint"
                        class="form-control form-select"
                      >
                        <option value="이용국가 내 네트워크 망 연결 시">
                          이용국가 내 네트워크 망 연결 시
                        </option>
                        <option value="기재한 개통 희망일">
                          기재한 개통 희망일
                        </option>
                        <option value="설치한 직후">설치한 직후</option>
                        <option value="직접입력">직접입력</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="day-standard">1일 판정기준</label>
                      <select
                        id="day-standard"
                        v-model="dayStandard"
                        class="form-control form-select"
                      >
                        <option value="사용직후24시간">사용직후24시간</option>
                        <option value="기준지역자정">기준지역자정</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>설치시점</label>
                      <select
                        id="install-point"
                        v-model="installPoint"
                        class="form-control form-select"
                      >
                        <option value="현지 도착 후">현지 도착 후</option>
                        <option value="출국 전">출국 전</option>
                        <option value="개통알림 받은 후">
                          개통알림 받은 후
                        </option>
                        <option value="리딤 후 30일 이내">
                          리딤 후 30일 이내
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="voucher-template">바우처유형</label>
                      <select
                        id="voucher-template"
                        v-model="voucherTemplate"
                        class="form-control form-select"
                      >
                        <option value="QR">QR</option>
                        <option value="QR_CMLink">QR_CMLink</option>
                        <option value="REDEEM">REDEEM</option>
                        <option value="REDEEM_RNR">REDEEM_RNR</option>
                        <option value="ESIM_DISCOVERY">ESIM_DISCOVERY</option>
                      </select>
                    </div>
                  </div>

                  <!-- <div class="col-6">
                    <div class="form-group">
                      <label for="api-at">API 연동</label>
                      <div class="input-group mb-0">
                        <span class="input-group-text">
                          <div class="form-check form-switch">
                            <input
                              id="api-at"
                              v-model="apiAt"
                              class="form-check-input"
                              type="checkbox"
                            />
                          </div>
                        </span>
                        <input
                          id="api-desc"
                          v-model="apiDesc"
                          :disabled="!apiAt"
                          type="text"
                          class="form-control"
                          placeholder=""
                          aria-label=""
                        />
                      </div>
                    </div>
                  </div> -->
                  <div class="col-12">
                    <div class="form-group">
                      <label for="link-division"
                        >연동방식{{ getLinkDataDesc }}</label
                      >
                      <div class="input-group mb-0">
                        <select
                          id="link-division"
                          v-model="linkDivision"
                          class="form-control form-select"
                        >
                          <option value="">없음</option>
                          <option value="API">API</option>
                          <option value="GOOGLE">Google</option>
                        </select>
                        <input
                          v-if="
                            linkDivision == 'API' || linkDivision == 'GOOGLE'
                          "
                          v-model="linkCol1"
                          type="text"
                          class="form-control flex-grow-1 ps-1"
                          :placeholder="
                            linkDivision == 'API' ? '플랜명' : 'GoogleSheet ID'
                          "
                          :aria-label="
                            linkDivision == 'API' ? '플랜명' : 'GoogleSheet ID'
                          "
                        />
                        <input
                          v-if="linkDivision == 'GOOGLE'"
                          v-model="linkCol2"
                          type="text"
                          class="form-control flex-grow-1 ps-1"
                          placeholder="GoogleSheet SheetName"
                          aria-label="GoogleSheet SheetName"
                        />
                        <input
                          v-if="linkDivision == 'GOOGLE'"
                          v-model="linkCol3"
                          type="text"
                          class="form-control flex-grow-1 ps-1"
                          placeholder="Plan"
                          aria-label="Plan"
                        />
                      </div>
                    </div>
                  </div>
                </form>
                <h4>메모</h4>
                <form role="form text-left" class="row" @submit.prevent>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="memo">메모</label>
                      <div class="input-group mb-3">
                        <textarea
                          id="memo"
                          v-model="memo"
                          type="text"
                          class="form-control"
                          placeholder="메모"
                          aria-label="메모"
                          rows="4"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </form>
                <div class="row">
                  <div class="col-12">
                    <div class="text-center">
                      <div class="row">
                        <div class="col-sm">
                          <soft-button
                            color="primary"
                            full-width="true"
                            :disabled="auth == 2"
                            @click="saveItemStock()"
                            >{{ btnSaveText }}</soft-button
                          >
                        </div>
                        <div class="col-sm">
                          <soft-button
                            color="secondary"
                            full-width="true"
                            @click="modalProduct.hide()"
                            >취소</soft-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import SoftButton from "@/components/SoftButton.vue";
// import VueMultiselect from "vue-multiselect";

import { TabulatorFull as Tabulator } from "tabulator-tables";

export default {
  name: "UsimStoreProductList",
  components: {
    SoftButton,
    // VueMultiselect,
  },
  data() {
    return {
      searchItemType: "",
      searchItIdOpt: "",
      searchCaId: "",
      searchSupplier: "",
      searchStName: "",

      modalModfy: false, //
      productList: [],
      prodCodeList: [],

      partnerList: [],

      supplierList: [],

      btnSaveText: "상품코드 등록",
      modalTitle: "상품코드 등록",

      stId: 0,
      itemType: "ESIM",
      itIdOpt: "",
      caId: "",
      stName: "",
      supplier: 0,
      roamingAt: false,
      dataText: "",
      callText: "",
      collectDateAt: false,
      collectImeiAt: false,
      collectEidAt: false,
      collectPassportAt: false,
      openPoint: "",
      installPoint: "",
      hotspotAt: false,
      hotspotDesc: "",
      apiAt: false,
      apiDesc: "",
      idExt: 0,
      mainTitle: "",
      subTitle: "",
      memo: "",
      apnText: "",
      dayStandard: "",
      esimType: "",
      defaultQrcode: "",
      linkDivision: "",
      linkCol1: "",
      linkCol2: "",
      linkCol3: "",
      linkCol4: "",
      linkCol5: "",
      voucherTemplate: "",

      btnProdCodeCheckDisabled: false,
      btnOptCodeCheckDisabled: false,
      btnGygOptionIdCheckDisabled: false,

      prodCodeCheck: false,
      optCodeCheck: false,
      gygOptionIdCheck: false,

      auth: 0,

      tabulator: null, //variable to hold your table
    };
  },
  computed: {
    getUsePlatform() {
      return (usePlatform) => {
        const platform = usePlatform;
        let htmlUsePlatform = "";
        platform.forEach((item) => {
          let bClass = "badge-secondary";
          if (item === "CAFE24") {
            bClass = "badge-info";
          } else if (item === "GYG") {
            bClass = "badge-success";
          }
          htmlUsePlatform += `<span class="platform-item badge ${bClass}">${item.substr(
            0,
            1
          )}</span>`;
        });
        return htmlUsePlatform;
      };
    },
    checkPlatformGyg() {
      let returnValue = this.usePlatform.indexOf("GYG") > -1;
      return returnValue;
    },
    getLinkDataDesc() {
      let desc = "";
      if (this.linkDivision == "API") {
        desc = "( 플랜명 )";
      } else if (this.linkDivision == "GOOGLE") {
        desc = "( 구글시트ID / 시트명 / 플랜명 )";
      }

      return desc;
    },
  },
  watch: {
    itIdOpt(value, oldValue) {
      if (!this.modalModfy && value !== oldValue) {
        this.btnProdCodeCheckDisabled = false;
        this.prodCodeCheck = false;
      }
    },
  },
  mounted() {
    //instantiate Tabulator when element is mounted
    this.tabulator = new Tabulator(this.$refs.table, {
      layout: "fitColumns",
      data: this.productList, //link data to table
      reactiveData: true, //enable data reactivity
      renderHorizontal: "virtual",
      pagination: "local",
      paginationSize: 50,
      paginationSizeSelector: [25, 50, 100],
      paginationCounter: "rows",
      columns: [
        { title: "순번", formatter: "rownum", width: 80, headerSort: false },
        { title: "상품코드", field: "it_id_opt", width: 250 },
        { title: "상품유형", field: "item_type_string" },
        { title: "상품명(유심스토어)", field: "st_name" },
        {
          title: "상품명(바우처)",
          field: "st_name",
          formatter: this.voucherNameFormatter,
        },
        { title: "지역", field: "ca_id" },
        { title: "공급처", field: "supplier_name" },
      ], //define table columns
    });
    const thisObj = this;
    this.tabulator.on("rowClick", function (e, row) {
      const data = row.getData();
      const st_id = data.st_id;

      // console.log("rowClick data");
      // console.log(data.st_id);

      const idx = thisObj.productList.findIndex((x) => x.st_id == st_id);

      thisObj.showProductModal(true, idx);
    });
    const userInfo = this.$cookies.get("userInfo");
    this.auth = userInfo.auth;

    this.getSupplierList();
    this.getProductList();
  },
  methods: {
    searchInit() {
      this.searchItemType = "";
      this.searchItIdOpt = "";
      this.searchCaId = "";
      this.searchSupplier = "";
      this.searchStName = "";
    },
    getSupplierList() {
      this.$axios
        .post(process.env.VUE_APP_BACKEND_URL + "api/usimstore/getSupplierList")
        .then((response) => {
          // 실제 API를 요청한다/
          console.log(response.data.result);

          this.supplierList = response.data.result;
        });
    },
    getPartnerList() {
      this.$axios
        .post(process.env.VUE_APP_BACKEND_URL + "api/partner/list")
        .then((response) => {
          // 실제 API를 요청한다/
          console.log(response.data.result);

          this.partnerList = response.data.result;
        });
    },
    getProdInfoAll() {
      this.$axios
        .post(process.env.VUE_APP_BACKEND_URL + "api/product/prodCodeList")
        .then((response) => {
          // 실제 API를 요청한다/
          console.log(response.data.result);

          this.prodCodeList = response.data.result;
        });
    },
    showProductModal(modify, index) {
      console.debug("showAdminModal CALL", index);
      this.initData();

      this.modalModfy = modify;

      if (modify) {
        this.btnSaveText = "상품코드 수정";
        this.modalTitle = "상품코드 수정";

        this.stId = this.productList[index].st_id;
        this.itemType = this.productList[index].item_type;
        this.itIdOpt = this.productList[index].it_id_opt;
        this.caId = this.productList[index].ca_id;
        this.stName = this.productList[index].st_name;
        this.supplier = this.productList[index].supplier;
        this.roamingAt = this.productList[index].roaming_at ? true : false;
        this.dataText = this.productList[index].data_text;
        this.callText = this.productList[index].call_text;

        this.collectDateAt = this.productList[index].collect_date_at
          ? true
          : false;
        this.collectImeiAt = this.productList[index].collect_imei_at
          ? true
          : false;
        this.collectEidAt = this.productList[index].collect_eid_at
          ? true
          : false;
        this.collectPassportAt = this.productList[index].collect_passport_at
          ? true
          : false;
        this.openPoint = this.productList[index].open_point;
        this.installPoint = this.productList[index].install_point;
        this.hotspotAt = this.productList[index].hotspot_at ? true : false;
        this.hotspotDesc = this.productList[index].hotspot_desc;
        this.apiAt = this.productList[index].api_at ? true : false;
        this.apiDesc = this.productList[index].api_desc;

        this.idExt = this.productList[index].ext_id;
        this.mainTitle = this.productList[index].main_title;
        this.subTitle = this.productList[index].sub_title;
        this.memo = this.productList[index].memo;
        this.apnText = this.productList[index].apn_text;
        this.dayStandard = this.productList[index].day_standard;
        this.esimType = this.productList[index].esim_type;
        this.defaultQrcode = this.productList[index].default_qrcode;

        this.linkDivision = this.productList[index].link_division;
        this.linkCol1 = this.productList[index].link_col_1;
        this.linkCol2 = this.productList[index].link_col_2;
        this.linkCol3 = this.productList[index].link_col_3;
        this.linkCol4 = this.productList[index].link_col_4;
        this.linkCol5 = this.productList[index].link_col_5;
        this.voucherTemplate = this.productList[index].voucher_template;
      }

      this.modalProduct = new Modal(document.getElementById("modal-product"));
      this.modalProduct.show();
    },
    getProductList() {
      // this.productDataTable.clear();
      // this.productDataTable.data = [];

      const param = {
        searchItemType: this.searchItemType,
        searchItIdOpt: this.searchItIdOpt,
        searchCaId: this.searchCaId,
        searchSupplier: this.searchSupplier,
        searchStName: this.searchStName,
      };
      this.$axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "api/usimstore/getItemList",
          param
        )
        .then((response) => {
          // 실제 API를 요청한다/
          console.log(response.data.result);

          this.productList = response.data.result;

          this.tabulator.setData(this.productList);

          // let data = this.productList
          // let obj = {
          //     // Quickly get the headings
          //     headings: Object.keys(data[0]),

          //     // data array
          //     data
          // };

          // // Loop over the objects to get the values
          // for ( let i = 0; i < data.length; i++ ) {

          //     obj.data[i] = [];

          //     for (let p in data[i]) {
          //         // eslint-disable-next-line no-prototype-builtins
          //         if( data[i].hasOwnProperty(p) ) {
          //             console.log(data[i][p])
          //             const d = data[i][p]!==null?data[i][p]+"":""
          //             obj.data[i].push(d);
          //         }
          //     }
          // }

          // console.log(obj)

          // this.productDataTable.insert(obj)

          //         let newData = {
          //             headings: [
          //                 "Name",
          //                 "Position",
          //                 "Town",
          //                 "Ext.",
          //                 "Start Date",
          //                 "Salary"
          //             ],
          //       data: [
          //         [
          //                 "Cedric Kelly",
          //                 "Senior Javascript Developer",
          //                 "Edinburgh",
          //                 "6224",
          //                 "2012/03/29",
          //                 "$433,060"
          //             ],
          //             [
          //                 "Airi Satou",
          //                 "Accountant",
          //                 "Tokyo",
          //                 "5407",
          //                 "2008/11/28",
          //                 "$162,700"
          //             ],
          //       ]
          // };
          // this.productDataTable.insert(newData)

          // this.movieList = response.data.response.body.items.item; // 받아온 데이터를 movieList 배열에 넣어준다.
          // console.log('영화목록 : ' + this.movieList);
        });
    },
    initData() {
      this.btnSaveText = "상품코드 추가";
      this.modalTitle = "상품코드 추가";

      this.stId = 0;
      this.itemType = "ESIM";
      this.itIdOpt = "";
      this.caId = "";
      this.stName = "";
      this.supplier = 0;
      this.roamingAt = false;
      this.dataText = "";
      this.callText = "";
      this.collectDateAt = false;
      this.collectImeiAt = false;
      this.collectEidAt = false;
      this.collectPassportAt = false;
      this.openPoint = "";
      this.installPoint = "";
      this.hotspotAt = false;
      this.hotspotDesc = "";
      this.apiAt = false;
      this.apiDesc = "";
      this.idExt = 0;
      this.mainTitle = "";
      this.subTitle = "";
      this.memo = "";
      this.apnText = "";
      this.dayStandard = "";
      this.esimType = "";
      this.defaultQrcode = "";

      this.linkDivision = "";
      this.linkCol1 = "";
      this.linkCol2 = "";
      this.linkCol3 = "";
      this.linkCol4 = "";
      this.linkCol5 = "";
      this.voucherTemplate = "";

      this.btnProdCodeCheckDisabled = false;
      this.btnOptCodeCheckDisabled = false;
      this.btnGygOptionIdCheckDisabled = false;

      this.prodCodeCheck = false;
      this.optCodeCheck = false;
      this.gygOptionCheck = false;
    },
    saveItemStock() {
      if (!this.modalModfy && this.itIdOpt === "") {
        alert("상품코드를 입력해주세요.");
        return;
      }

      if (!this.modalModfy && !this.prodCodeCheck) {
        alert("상품코드 중복확인을 해주세요.");
        return;
      }

      if (this.stName === "") {
        alert("상품명을 입력해주세요.");
        return;
      }

      const param = {
        stId: this.stId,
        itemType: this.itemType,
        itIdOpt: this.itIdOpt,
        caId: this.caId,
        stName: this.stName,
        supplier: this.supplier,
        roamingAt: this.roamingAt ? 1 : 0,
        dataText: this.dataText,
        callText: this.callText,
        collectDateAt: this.collectDateAt ? 1 : 0,
        collectImeiAt: this.collectImeiAt ? 1 : 0,
        collectEidAt: this.collectEidAt ? 1 : 0,
        collectPassportAt: this.collectPassportAt ? 1 : 0,
        openPoint: this.openPoint,
        installPoint: this.installPoint,
        hotspotAt: this.hotspotAt ? 1 : 0,
        hotspotDesc: this.hotspotDesc,
        apiAt: this.apiAt ? 1 : 0,
        apiDesc: this.apiDesc,
        idExt: this.idExt,
        mainTitle: this.mainTitle,
        subTitle: this.subTitle,
        memo: this.memo,
        apnText: this.apnText,
        dayStandard: this.dayStandard,
        esimType: this.esimType,
        defaultQrcode: this.defaultQrcode,
        linkDivision: this.linkDivision,
        linkCol1: this.linkCol1,
        linkCol2: this.linkCol2,
        linkCol3: this.linkCol3,
        linkCol4: this.linkCol4,
        linkCol5: this.linkCol5,
        voucherTemplate: this.voucherTemplate,
      };

      const modeUri = this.modalModfy ? "itemUpdate" : "itemInsert";
      const modeText = this.modalModfy ? "수정" : "등록";

      this.$axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "api/usimstore/" + modeUri,
          param
        )
        .then((response) => {
          // 실제 API를 요청한다/
          console.log(response.data.result);

          alert("정보 " + modeText + "이 완료되었습니다.");

          this.modalProduct.hide();

          this.getProductList();
        });
    },

    deleteProduct() {
      if (!confirm("선택하신 상품을 삭제하시겠습니까?")) {
        return;
      }

      const param = {
        no: this.no,
      };

      this.$axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "api/usimstore/itemDelete",
          param
        )
        .then((response) => {
          // 실제 API를 요청한다/
          console.log(response.data.result);

          alert("상품 삭제가 완료되었습니다.");

          this.modalProduct.hide();

          this.getProdInfoAll();
          this.getProductList();
        });
    },

    checkProdCode() {
      if (this.itIdOpt === "") {
        alert("상품코드를 입력해주세요.");
        return;
      }

      const param = {
        itIdOpt: this.itIdOpt,
      };

      this.$axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "api/usimstore/checkItIdOpt",
          param
        )
        .then((response) => {
          // 실제 API를 요청한다/
          console.log(response.data.result);
          if (response.data.result[0].count === 0) {
            alert("사용가능한 상품코드입니다.");
            this.btnProdCodeCheckDisabled = true;
            this.prodCodeCheck = true;
            return;
          } else {
            alert("사용중인 상품코드입니다.\n다른 상품코드를 입력해주세요.");
            this.btnProdCodeCheckDisabled = false;
            this.prodCodeCheck = false;
            return;
          }
        });
    },

    prodCodeClickHandeler(e, cell) {
      const data = cell.getRow().getData();
      const no = data.no;

      const idx = this.productList.findIndex((x) => x.no == no);

      this.showProductModal(true, idx);
    },

    addPartnerMailList(newTag) {
      this.partnerMailList.push(newTag);
    },

    voucherNameFormatter(cell) {
      const data = cell.getRow().getData();

      return `${data.main_title} ${data.sub_title}`;
    },
  },
};
</script>
<style lang="scss">
.platform-wrap:deep(.platform-item) {
  :first {
    margin-left: 0;
  }
  margin-left: 5px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
