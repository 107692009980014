<template>
  <div class="py-4 container-fluid">
    <div class="mb-4 row">
      <div class="mb-4 col-lg-6 mb-lg-0">
        <!-- line chart -->
        <div class="card z-index-2">
          <div class="p-3 pb-0 card-header">
            <div class="row">
              <div class="col-md-6">
                <h6 class="mb-0">eSIM 판매량</h6>
              </div>
              <div
                class="col-md-6 d-flex justify-content-end align-items-center"
              >
                <i class="far fa-calendar-alt me-2"></i>
                <flat-picker
                    v-model="searchOrderDateRange"
                    :config="{ allowInput: true, mode: 'range', parseDate: false }"
                    class="form-control datepicker form-control-sm"
                    @on-change="onDateChange"
                  >
                  </flat-picker>
              </div>
            </div>
            
            <!-- eslint-disable vue/no-v-html -->
            <!-- <p v-if="description" class="text-sm" v-html="description" /> -->
          </div>
          <div class="p-3 card-body">
            <div class="chart">
              <canvas id="esimSalesChart" class="chart-canvas" height="300"></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card h-100">
          <div class="p-3 pb-0 card-header">
            <div class="row">
              <div class="col-md-6">
                <h6 class="mb-0">판매량 TOP10</h6>
              </div>
              <div
                class="col-md-6 d-flex justify-content-end align-items-center"
              >
                <i class="far fa-calendar-alt me-2"></i>
                <flat-picker
                    v-model="searchOrderDateRange"
                    :config="{ allowInput: true, mode: 'range', parseDate: false }"
                    class="form-control datepicker form-control-sm"
                    @on-change="onDateChange"
                  >
                  </flat-picker>
              </div>
            </div>
          </div>
          <div class="p-3 card-body">
            <ul class="list-group">
              <li
                v-for="({ product_name, sales_cnt, color }, index) of productTopObj2"
                :key="index"
                class="pb-0 border-0 list-group-item justify-content-between ps-0 border-radius-lg"
              >
                <div class="d-flex">
                  <div class="d-flex align-items-center">
                    <div class="d-flex flex-column">
                      <h6 class="mb-1 text-sm text-dark">{{index+1}}. {{ product_name }}</h6>
                    </div>
                  </div>
                  <div
                    class="text-sm d-flex align-items-center text-gradient font-weight-bold ms-auto"
                    :class="`text-${color}`"
                  >
                    {{ sales_cnt }}
                  </div>
                </div>
                <hr
                  v-if="horizontalBreak && index !== item.length - 1"
                  class="mt-3 mb-2 horizontal dark"
                />
                <div v-else class="mt-3 mb-2" />
              </li>
            </ul>
          </div>
        </div>
        <!-- <ranking-list-card
            :card="{ title: '판매량 TOP10', date: '01 - 07 June 2022' }"
            :item="[
              {
                title: 'via PayPal',
                date: '07 June 2021, at 09:00 AM',
                amount: '+ $ 4,999',
                icon: 'fa-arrow-up',
                color: 'success',
              },
              {
                title: 'Partner #90211',
                date: '07 June 2021, at 05:50 AM',
                amount: '+ $ 700',
                icon: 'fa-arrow-up',
                color: 'success',
              },
              {
                title: 'Services',
                date: '07 June 2021, at 07:10 PM',
                amount: '- $ 1,800',
                icon: 'fa-arrow-down',
                color: 'danger',
              },
            ]"
          /> -->
      </div>
    </div>
    <div class="mb-4 row">
      <div class="col-lg-12">
        <div class="card mt-4">
          <div class="p-3 pb-0 card-header">
            <div class="row">
              <div class="col-md-6">
                <h6 class="mb-0">판매량 TOP10</h6>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table id="product-stock-list" class="table table-flush">
              <thead class="thead-light">
                <!-- <tr>
                  <th data-sortable="false">상품코드1</th>
                  <th data-sortable="false">상품명</th>
                  <th data-sortable="true">정상(만료임박)</th>
                  <th data-sortable="true">만료임박</th>
                  <th data-sortable="true">만료</th>
                </tr> -->
              </thead>
              <tbody>
                <!--
                <tr v-for="({ prod_code, prod_name, ready, imminent, expired }, index) of productStockObj"
                :key="index">
                  <td>
                    <div class="d-flex align-items-center">
                      <p class="text-xs font-weight-bold ms-2 mb-0">{{prod_code}}</p>
                    </div>
                  </td>
                  <td class="font-weight-bold">
                    <span class="my-2 text-xs">{{prod_name }}</span>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <span>{{ ready + imminent }}</span>
                    </div>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <span>{{ imminent }}</span>
                    </div>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <span class="my-2 text-xs">{{ expired }}</span>
                  </td>
                </tr>
                
                <tr>
                  <td>
                    <div class="d-flex align-items-center">
                      <soft-checkbox />
                      <p class="text-xs font-weight-bold ms-2 mb-0">#10422</p>
                    </div>
                  </td>
                  <td class="font-weight-bold">
                    <span class="my-2 text-xs">1 Nov, 10:53 AM</span>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <soft-button
                        color="success"
                        variant="outline"
                        class="btn-icon-only btn-rounded mb-0 me-2 btn-sm d-flex align-items-center justify-content-center"
                      >
                        <i class="fas fa-check" aria-hidden="true"></i>
                      </soft-button>
                      <span>Paid</span>
                    </div>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <soft-avatar
                        :img="img2"
                        size="xs"
                        class="me-2"
                        alt="user image"
                        circular
                      />
                      <span>Alice Murinho</span>
                    </div>
                  </td>
                  <td class="font-weight-bold">
                    <span class="my-2 text-xs">Valvet T-shirt</span>
                  </td>
                  <td class="font-weight-bold">
                    <span class="my-2 text-xs">$42,00</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex align-items-center">
                      <soft-checkbox />
                      <p class="text-xs font-weight-bold ms-2 mb-0">#10423</p>
                    </div>
                  </td>
                  <td class="font-weight-bold">
                    <span class="my-2 text-xs">1 Nov, 11:13 AM</span>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <soft-button
                        color="dark"
                        variant="outline"
                        class="btn-icon-only btn-rounded mb-0 me-2 btn-sm d-flex align-items-center justify-content-center"
                      >
                        <i class="fas fa-undo" aria-hidden="true"></i>
                      </soft-button>
                      <span>Refunded</span>
                    </div>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <div class="avatar avatar-xs me-2 bg-gradient-dark">
                        <span>M</span>
                      </div>
                      <span>Michael Mirra</span>
                    </div>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <span class="my-2 text-xs">
                      Leather Wallet
                      <span class="text-secondary ms-2">+1 more</span>
                    </span>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <span class="my-2 text-xs">$25,50</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex align-items-center">
                      <soft-checkbox />
                      <p class="text-xs font-weight-bold ms-2 mb-0">#10424</p>
                    </div>
                  </td>
                  <td class="font-weight-bold">
                    <span class="my-2 text-xs">1 Nov, 12:20 PM</span>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <soft-button
                        color="success"
                        variant="outline"
                        class="btn-icon-only btn-rounded mb-0 me-2 btn-sm d-flex align-items-center justify-content-center"
                      >
                        <i class="fas fa-check" aria-hidden="true"></i>
                      </soft-button>
                      <span>Paid</span>
                    </div>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <div class="d-flex align-items-center">
                        <soft-avatar
                          :img="img3"
                          size="xs"
                          class="me-2"
                          alt="user image"
                          circular
                        />
                        <span>Andrew Nichel</span>
                      </div>
                    </div>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <span class="my-2 text-xs">Bracelet Onu-Lino</span>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <span class="my-2 text-xs">$19,40</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex align-items-center">
                      <soft-checkbox />
                      <p class="text-xs font-weight-bold ms-2 mb-0">#10425</p>
                    </div>
                  </td>
                  <td class="font-weight-bold">
                    <span class="my-2 text-xs">1 Nov, 1:40 PM</span>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <soft-button
                        color="danger"
                        variant="outline"
                        class="btn-icon-only btn-rounded mb-0 me-2 btn-sm d-flex align-items-center justify-content-center"
                      >
                        <i class="fas fa-times" aria-hidden="true"></i>
                      </soft-button>
                      <span>Canceled</span>
                    </div>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <div class="d-flex align-items-center">
                        <soft-avatar
                          :img="img4"
                          size="xs"
                          class="me-2"
                          alt="user image"
                          circular
                        />
                        <span>Sebastian Koga</span>
                      </div>
                    </div>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <span class="my-2 text-xs">
                      Phone Case Pink
                      <span class="text-secondary ms-2">x 2</span>
                    </span>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <span class="my-2 text-xs">$44,90</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex align-items-center">
                      <soft-checkbox />
                      <p class="text-xs font-weight-bold ms-2 mb-0">#10426</p>
                    </div>
                  </td>
                  <td class="font-weight-bold">
                    <span class="my-2 text-xs">1 Nov, 2:19 AM</span>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <soft-button
                        color="success"
                        variant="outline"
                        class="btn-icon-only btn-rounded mb-0 me-2 btn-sm d-flex align-items-center justify-content-center"
                      >
                        <i class="fas fa-check" aria-hidden="true"></i>
                      </soft-button>
                      <span>Paid</span>
                    </div>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <div class="avatar avatar-xs me-2 bg-gradient-success">
                        <span>L</span>
                      </div>
                      <span>Laur Gilbert</span>
                    </div>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <span class="my-2 text-xs">Backpack Niver</span>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <span class="my-2 text-xs">$112,50</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex align-items-center">
                      <soft-checkbox />
                      <p class="text-xs font-weight-bold ms-2 mb-0">#10427</p>
                    </div>
                  </td>
                  <td class="font-weight-bold">
                    <span class="my-2 text-xs">1 Nov, 3:42 AM</span>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <soft-button
                        color="success"
                        variant="outline"
                        class="btn-icon-only btn-rounded mb-0 me-2 btn-sm d-flex align-items-center justify-content-center"
                      >
                        <i class="fas fa-check" aria-hidden="true"></i>
                      </soft-button>
                      <span>Paid</span>
                    </div>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <div class="avatar avatar-xs me-2 bg-gradient-dark">
                        <span>I</span>
                      </div>
                      <span>Iryna Innda</span>
                    </div>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <span class="my-2 text-xs">Adidas Vio</span>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <span class="my-2 text-xs">$200,00</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex align-items-center">
                      <soft-checkbox />
                      <p class="text-xs font-weight-bold ms-2 mb-0">#10428</p>
                    </div>
                  </td>
                  <td class="font-weight-bold">
                    <span class="my-2 text-xs">2 Nov, 9:32 AM</span>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <soft-button
                        color="success"
                        variant="outline"
                        class="btn-icon-only btn-rounded mb-0 me-2 btn-sm d-flex align-items-center justify-content-center"
                      >
                        <i class="fas fa-check" aria-hidden="true"></i>
                      </soft-button>
                      <span>Paid</span>
                    </div>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <div class="avatar avatar-xs me-2 bg-gradient-dark">
                        <span>A</span>
                      </div>
                      <span>Arrias Liunda</span>
                    </div>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <span class="my-2 text-xs">Airpods 2 Gen</span>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <span class="my-2 text-xs">$350,00</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex align-items-center">
                      <soft-checkbox />
                      <p class="text-xs font-weight-bold ms-2 mb-0">#10429</p>
                    </div>
                  </td>
                  <td class="font-weight-bold">
                    <span class="my-2 text-xs">2 Nov, 10:14 AM</span>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <soft-button
                        color="success"
                        variant="outline"
                        class="btn-icon-only btn-rounded mb-0 me-2 btn-sm d-flex align-items-center justify-content-center"
                      >
                        <i class="fas fa-check" aria-hidden="true"></i>
                      </soft-button>
                      <span>Paid</span>
                    </div>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <div class="d-flex align-items-center">
                        <soft-avatar
                          :img="img5"
                          size="xs"
                          class="me-2"
                          alt="user image"
                          circular
                        />
                        <span>Rugna Ilpio</span>
                      </div>
                    </div>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <span class="my-2 text-xs">Bracelet Warret</span>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <span class="my-2 text-xs">$15,00</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex align-items-center">
                      <soft-checkbox />
                      <p class="text-xs font-weight-bold ms-2 mb-0">#10430</p>
                    </div>
                  </td>
                  <td class="font-weight-bold">
                    <span class="my-2 text-xs">2 Nov, 12:56 PM</span>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <soft-button
                        color="dark"
                        variant="outline"
                        class="btn-icon-only btn-rounded mb-0 me-2 btn-sm d-flex align-items-center justify-content-center"
                      >
                        <i class="fas fa-undo" aria-hidden="true"></i>
                      </soft-button>
                      <span>Refunded</span>
                    </div>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <div class="d-flex align-items-center">
                        <soft-avatar
                          :img="img6"
                          size="xs"
                          class="me-2"
                          alt="user image"
                          circular
                        />
                        <span>Anna Landa</span>
                      </div>
                    </div>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <span class="my-2 text-xs">
                      Watter Bottle India
                      <span class="text-secondary ms-2">x 3</span>
                    </span>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <span class="my-2 text-xs">$25,00</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex align-items-center">
                      <soft-checkbox />
                      <p class="text-xs font-weight-bold ms-2 mb-0">#10431</p>
                    </div>
                  </td>
                  <td class="font-weight-bold">
                    <span class="my-2 text-xs">2 Nov, 3:12 PM</span>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <soft-button
                        color="success"
                        variant="outline"
                        class="btn-icon-only btn-rounded mb-0 me-2 btn-sm d-flex align-items-center justify-content-center"
                      >
                        <i class="fas fa-check" aria-hidden="true"></i>
                      </soft-button>
                      <span>Paid</span>
                    </div>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <div class="avatar avatar-xs me-2 bg-gradient-dark">
                        <span>K</span>
                      </div>
                      <span>Karl Innas</span>
                    </div>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <span class="my-2 text-xs">Kitchen Gadgets</span>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <span class="my-2 text-xs">$164,90</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex align-items-center">
                      <soft-checkbox />
                      <p class="text-xs font-weight-bold ms-2 mb-0">#10432</p>
                    </div>
                  </td>
                  <td class="font-weight-bold">
                    <span class="my-2 text-xs">2 Nov, 5:12 PM</span>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <soft-button
                        color="success"
                        variant="outline"
                        class="btn-icon-only btn-rounded mb-0 me-2 btn-sm d-flex align-items-center justify-content-center"
                      >
                        <i class="fas fa-check" aria-hidden="true"></i>
                      </soft-button>
                      <span>Paid</span>
                    </div>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <div class="avatar avatar-xs me-2 bg-gradient-info">
                        <span>O</span>
                      </div>
                      <span>Oana Kilas</span>
                    </div>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <span class="my-2 text-xs">Office Papers</span>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <span class="my-2 text-xs">$23,90</span>
                  </td>
                </tr>-->
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DataTable } from "simple-datatables";
// import RankingListCard from "../../examples/Cards/RankingListCard.vue";
import Chart from "chart.js/auto";

import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

export default {
  name: "EsimDashboard",
  components: {
    // RankingListCard,
    flatPicker
  },
  data() {
    return {
      searchOrderDateStart: '2022-01-01',
      searchOrderDateEnd: '2022-12-31',

      esimSalesObj: {
        labels: [],
        datasets: [
          {
            label: "",
            data: [],
          }
        ]
      },
      productTopObj: {
        labels: [],
        datasets: {
          label: "판매량",
          data: []
        }
      },
      productTopObj2: [],
      productStockObj: [],

      tData: [
        [1, 2],
        [3, 4],
      ],

      productStockDatatable:null,

      searchOrderDateRange: null,
    };

    
  },
  computed: {
   
  },
  mounted() {

    dayjs.extend(customParseFormat)

    this.searchOrderDateRange = dayjs().subtract(7, 'day').format('YYYY-MM-DD') + " to " + dayjs().format('YYYY-MM-DD')

    if (document.getElementById("product-stock-list")) {
      this.productStockDatatable = new DataTable("#product-stock-list", {
        searchable: false,
        fixedHeight: false,
        perPageSelect: false,
        columns: [
        // Sort the second column in ascending order
        { 
          select: 1, 
          render: function(data) {
            return "<span class='text-sm font-weight-normal'>"+data+"</span>";
          }
        },

        // Set the third column as datetime string matching the format "DD/MM/YYY"
        { 
          select: 2,
          render: function(data) {
            return "<span class='text-sm font-weight-normal'>"+data+"</span>";
          }
        },

        // Disable sorting on the fourth and fifth columns
        {
          select: [3,4,5],
          render: function(data) {
            return "<span class='text-sm font-weight-normal'>"+data+"</span>";
          }
        },
    ]
      });
    }
    

    this.getEsimSales();
    this.getProductTop();
    this.getProductStock();
  },
  methods: {
    getEsimSales() {

      const aryRangeDate = this.searchOrderDateRange ? this.searchOrderDateRange.split(" to ") : []

      if(aryRangeDate.length == 1) {
        this.searchOrderDateStart = dayjs(this.searchOrderDateRange,"YYYY-MM-DD").startOf("date").format("YYYY-MM-DD HH:mm:ss")
        this.searchOrderDateEnd = dayjs(this.searchOrderDateRange,"YYYY-MM-DD").endOf("date").format("YYYY-MM-DD HH:mm:ss")
      }
      else if(aryRangeDate.length == 2) {
        this.searchOrderDateStart = dayjs(aryRangeDate[0],"YYYY-MM-DD").startOf("date").format("YYYY-MM-DD HH:mm:ss")
        this.searchOrderDateEnd = dayjs(aryRangeDate[1],"YYYY-MM-DD").endOf("date").format("YYYY-MM-DD HH:mm:ss")
      }

      const param = {
        searchOrderDateStart: this.searchOrderDateStart,
        searchOrderDateEnd: this.searchOrderDateEnd,
      }
      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/dashboard/esimSales", param).then((response) => { // 실제 API를 요청한다/
        console.log(response.data.result);

        const result = response.data.result;
        const labels = [];
        const data = [];

        result.forEach((item) => {
          labels.push(item.order_date);

          data.push(item.sales_cnt);
        })

        this.esimSalesObj.labels = labels;
        this.esimSalesObj.datasets = {
          label: "판매량",
          data: data,
        }

        this.makeEsimSalesChart();
      })

      
    },
    getProductTop() {

      const aryRangeDate = this.searchOrderDateRange ? this.searchOrderDateRange.split(" to ") : []

      if(aryRangeDate.length == 1) {
        this.searchOrderDateStart = dayjs(this.searchOrderDateRange,"YYYY-MM-DD").startOf("date").format("YYYY-MM-DD HH:mm:ss")
        this.searchOrderDateEnd = dayjs(this.searchOrderDateRange,"YYYY-MM-DD").endOf("date").format("YYYY-MM-DD HH:mm:ss")
      }
      else if(aryRangeDate.length == 2) {
        this.searchOrderDateStart = dayjs(aryRangeDate[0],"YYYY-MM-DD").startOf("date").format("YYYY-MM-DD HH:mm:ss")
        this.searchOrderDateEnd = dayjs(aryRangeDate[1],"YYYY-MM-DD").endOf("date").format("YYYY-MM-DD HH:mm:ss")
      }

      const param = {
        searchOrderDateStart: this.searchOrderDateStart,
        searchOrderDateEnd: this.searchOrderDateEnd,
        searchLimit: 10,
      }
      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/dashboard/productTop", param).then((response) => { // 실제 API를 요청한다/
        console.log(response.data.result);

        const result = response.data.result;
        this.productTopObj2 = []

        const labels = [];
        const data = [];

        result.forEach((item) => {
          labels.push(item.product_name);

          data.push(item.sales_cnt);

          this.productTopObj2.push({color:"success", ...item})
        })

        this.productTopObj.labels = labels;
        this.productTopObj.datasets = {
          label: "판매량",
          data: data,
        }

        // this.makeProductTopChart()
      })
    },
    getProductStock() {

      const param = {}
      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/dashboard/productStock", param).then((response) => { // 실제 API를 요청한다/
        console.log(response.data.result);

        this.productStockObj = response.data.result;

        let obj = {
            // Quickly get the headings
            headings: [
              "상품코드",
              "상품명",
              "정상(만료임박)",
              "만료임박",
              "만료",
            ],

            // data array
            data: []
        };

        this.productStockObj.forEach((item) => {
          console.log(item.expired)
          const rowData = [
            item.prod_code,
            item.prod_name,
            (item.ready + item.imminent) + "",
            item.imminent  +"",
            item.expired  +"",
          ];
          obj.data.push(rowData)
        });

        console.log(obj.data)

        this.productStockDatatable.insert(obj);

        // if (document.getElementById("order-list")) {
        //   new DataTable("#product-stock-list", {
        //     searchable: true,
        //     fixedHeight: false,
        //     perPageSelect: false,
        //   });
        // }
      })
    },

    makeEsimSalesChart() {
      console.log("makeEsimSalesChart CALL");
      // Bar chart
      var ctx = document.getElementById("esimSalesChart").getContext("2d");

      let chartStatus = Chart.getChart("esimSalesChart");
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }

      new Chart(ctx, {
        type: "bar",
        data: {
          labels: this.esimSalesObj.labels,
          datasets: [
            {
              label: this.esimSalesObj.datasets.label,
              weight: 5,
              borderWidth: 0,
              borderRadius: 4,
              backgroundColor: "#3A416F",
              data: this.esimSalesObj.datasets.data,
              fill: false,
              maxBarThickness: 35,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#9ca2b7",
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: true,
                drawTicks: true,
              },
              ticks: {
                display: true,
                color: "#9ca2b7",
                padding: 10,
              },
            },
          },
        },
      });
    },

    makeProductTopChart() {
      // Bar chart
      var ctx = document.getElementById("productTopChart").getContext("2d");

      let chartStatus = Chart.getChart("productTopChart");
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }

      new Chart(ctx, {
        type: "bar",
        data: {
          labels: this.productTopObj.labels,
          datasets: [
            {
              label: this.productTopObj.datasets.label,
              weight: 5,
              borderWidth: 0,
              borderRadius: 4,
              backgroundColor: "#3A416F",
              data: this.productTopObj.datasets.data,
              fill: false,
              maxBarThickness: 35,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#9ca2b7",
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: true,
                drawTicks: true,
              },
              ticks: {
                display: true,
                color: "#9ca2b7",
                padding: 10,
              },
            },
          },
        },
      });
    },

    onDateChange() {
      this.getEsimSales();
      this.getProductTop();
    }
  }
};
</script>
