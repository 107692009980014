<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">eSIM 개별현황</h5>
                <p class="mb-0 text-sm">
                  
                </p>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  
                  <soft-button color="primary" full-width="true" :disabled="auth==2" @click="showAdminModal(false)">+&nbsp; 유효기간연장</soft-button>
                </div>
              </div>
            </div>
            <div class="">
              
              <form class="row row-cols-lg-auto g-3 align-items-end">
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>상품코드</label>
                    <div class="input-group mb-0">
                      <input v-model="searchProdCode" type="text" class="form-control" placeholder="상품코드" aria-label="상품코드">
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>SIM 일련번호</label>
                    <div class="input-group mb-0">
                      <input v-model="searchSimNum" type="text" class="form-control" placeholder="SIM 일련번호" aria-label="SIM 일련번호">
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>상태</label>
                    <div class="input-group mb-0">
                      <select v-model="searchStatus"  class="form-control">
                        <option value="">전체</option>
                        <option value="READY">대기</option>
                        <option value="SHIPPED">출고</option>
                        <option value="ACTIVATE">개통</option>
                        <option value="EXPIRED">만료</option>
                        <option value="CANCELED">취소</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <button type="button" class="btn btn-primary mb-0" @click="getEsimList()">조회</button>
                  <button type="button" class="btn btn-secondary mb-0 ms-1" @click="initSearchData()">
                    <span class="btn-inner--icon"><i class="fas fa-sync-alt"></i></span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div ref="table"></div>
            <!-- <div class="table-responsive">
              <table id="esim-list" class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      상품코드
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      SIM 일련번호
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      SIM 유효기간
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      상태
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      출고일시
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      개통일시
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      사용량
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      
                    </th>
                  </tr>
                </thead>
                <tbody >
                  <tr v-for="(item, idx) in esimList" :key="item">
                    <td class="text-center">
                      {{item.prod_code}}
                    </td>
                    <td class="text-left">
                      {{item.sim_num}}
                    </td>
                    <td class="text-left">
                      {{item.expire_date}}
                    </td>
                    <td class="text-left">
                      {{getStatusName(item.status)}}
                    </td>
                    <td class="align-middle text-left text-sm">
                      <i class="fas fa-qrcode cursor-hand" @click="showEsimInfoModal(idx)"></i>
                    </td>
                    <td class="align-middle text-center">
                      {{getDateString(item.shipped_date)}}<br>
                      {{getTimeString(item.shipped_date)}}
                    </td>
                    <td class="align-middle text-center">
                      {{getDateString(item.activate_date)}}<br>
                      {{getTimeString(item.activate_date)}}
                    </td>
                    <td class="align-middle text-center">
                      {{item.used}}
                    </td>
                    <td class="align-middle text-left">
                      <i class="fas fa-sync-alt cursor-hand"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div id="modal-esim-info" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal-esim-info" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="card card-plain">
              <div class="card-header pb-0 text-left">
                <h3 class="font-weight-bolder text-primary text-gradient">eSIM 상태 열람 및 변경</h3>
              </div>
              <div class="card-body">
                <form role="form text-left" @submit.prevent>
                  <div class="form-group">
                    <label>상품코드</label>
                    <div class="input-group mb-3">
                      <input v-model="prodCode" type="text" class="form-control" placeholder="상품코드" aria-label="상품코드" disabled>
                    </div>
                  </div>
                  <label>상품명</label>
                  <div class="input-group mb-3">
                    <input v-model="prodName" type="text" class="form-control" placeholder="상품명" aria-label="상품명" disabled>
                  </div>
                  <label>ICCID</label>
                  <div class="input-group mb-3">
                    <input v-model="simNum" type="text" class="form-control" placeholder="SIM 번호" aria-label="SIM 번호" disabled>
                  </div>
                  <label>만료일</label>
                  <div class="input-group mb-3">
                    <input v-model="expireDate" type="text" class="form-control" placeholder="만료일" aria-label="만료일" disabled>
                  </div>
                  <label>활성화코드</label>
                  <div class="input-group mb-3">
                    <input v-model="actCode" type="text" class="form-control" placeholder="활성화코드" aria-label="활성화코드" disabled>
                  </div>
                  <label>QR CODE</label>
                  <div class="input-group mb-3">
                    <QRCodeVue3 ref="qrCodeImage" myclass="my-qur" :value="actCode" :width="200" :height="200" file-ext="png" download-button="QR Download" :download-options="{ name: prodCode + '_' + simNum +'_qrcode', extension: 'png' }"
                    :corners-square-options="{ type: 'square', color: '#000000' }"
                    :corners-dot-options="{ type: 'square', color: '#000000' }"
                    :dots-options="{
                      type: 'square',
                      color: '#000000',
                    }"
                    />
                  </div>
                  <div>
                    <soft-button color="info" @click="downloadQrcode()">QR이미지 다운로드</soft-button>
                  </div>
                  <div class="form-group">
                    <label for="admin-auth">심상태<span class="text-red">*</span></label>
                    <div class="input-group mb-3">
                      <input v-model="statusName" type="text" class="form-control" placeholder="상태" aria-label="상태" disabled>
                      <span v-show="auth!=2" class="input-group-text"><i class="fas fa-arrow-right"></i></span>
                      <select v-show="auth!=2" id="change-status" v-model="changeStatus"  class="form-control">
                        <option value="">선택</option>
                        <option value="READY">대기</option>
                        <option value="SHIPPED">출고</option>
                        <option value="ACTIVATED">개통</option>
                        <option value="EXPIRED">만료</option>
                        <option value="CANCELED">취소</option>
                      </select>
                    </div>
                  </div>
                  <div class="text-center">
                    <div class="row">
                      <div class="col-sm">
                        <soft-button color="primary" full-width="true" :disabled="auth==2" @click="changeEsimStatus()">변경</soft-button>
                      </div>
                      <div class="col-sm">
                        <soft-button color="secondary"  full-width="true" @click="modalEsimInfo.hide()">취소</soft-button>
                      </div>
                    </div>  
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
// import { DataTable } from "simple-datatables";
import { Modal } from "bootstrap";
import SoftButton from "@/components/SoftButton.vue";
import QRCodeVue3 from "qrcode-vue3";

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import {TabulatorFull as Tabulator} from 'tabulator-tables'; 


export default {
  name: "AdminLists",
  components: {
    SoftButton,
    QRCodeVue3,
  },
  data() {
    return {
      searchProdCode: "",
      searchSimNum: "",
      searchStatus: "",

      esimList: [],

      no: 0,
      prodNo: 0,
      prodOptNo: 0,
      prodCode: "",
      prodName: "",
      simType: "",
      simNum: "",
      expireDate: "",
      actCode: "",
      status: "",
      statusName: "",
      changeStatus: "",

      auth: 0,

      tabulator: null, //variable
    }
  },
  computed: {
    getStatusName() {
      return (status) => {
        if(status === "READY") {
          return "대기"
        }
        else if(status === "IMMINENT") {
          return "만료임박"
        }
        else if(status === "SHIPPED") {
          return "출고"
        }
        else if(status === "ACTIVATED") {
          return "개통"
        }
        else if(status === "EXPIRED") {
          return "만료"
        }
        else if(status === "CANCELED") {
          return "취소"
        }
      }
    },
    getDateString() {
      return (date) =>{
        return date ? dayjs(date).format("YYYY-MM-DD") : ""
      }
    }, 
    getTimeString() {
      return (date) =>{
        return date ? dayjs(date).format("HH:mm:ss") : ""
      }
    }, 
  },
  mounted() {
    dayjs.extend(customParseFormat)

    const userInfo = this.$cookies.get("userInfo")
    this.auth = userInfo.auth;

    //instantiate Tabulator when element is mounted
    this.tabulator = new Tabulator(this.$refs.table, {
      data: this.productList, //link data to table
      reactiveData:true, //enable data reactivity
      renderHorizontal:"virtual",
      pagination:"local",
      paginationSize:50,
      paginationSizeSelector:[25, 50, 100],
      paginationCounter:"rows",
      columns: [
        { title: "상품코드", field: "prod_code", width: 250},
        { title: "SIM 일련번호", field: "sim_num" },
        { title: "SIM 유효기간", field: "expire_date", hozAlign: "center"},
        { title: "상태", field: "status", formatter:this.statusFormatter},
        { title: "QR", formatter:this.qrFormatter, cellClick: this.qrClickHandeler, headerSort:false },
        { title: "출고일시", field: "shipped_date" ,formatter:this.shippedDateFormatter, width: 150,  hozAlign: "center"},
        { title: "개통일시", field: "activate_date", formatter:this.activateDateFormatter,width: 150, hozAlign: "center" },
        { title: "사용량", field: "used"},
        { title: "", formatter:this.refreshFormatter, headerSort:false },
      ], //define table columns
    });

    if (document.getElementById("esim-list")) {
      // const adminDataTable = new DataTable("#admin-list", {
      //   searchable: false,
      //   fixedHeight: false,
      //   perPage: 10
      // });

      // adminDataTable.body.addEventListener("click", e => {
      //   console.log("DataTable CLick Event ");
      //   console.log(e.target.getAttribute("data-id"));
      //   console.log(e.target.getAttribute("data-type"));
      //   if (e.target.getAttribute("data-type") === "EDIT") {

      //     const index = parseInt(e.target.getAttribute("data-index"), 10)
      //     // const row = adminDataTable.activeRows[index]
      //     // let message = [
      //     //   "This is row ",
      //     //   (row.rowIndex),
      //     //   " of ",
      //     //   adminDataTable.options.perPage,
      //     //   " rendered rows and row ",
      //     //   (index + 1),
      //     //   " of ",
      //     //   adminDataTable.data.length,
      //     //   " total rows."
      //     // ]

      //     // console.log(adminDataTable.data);

      //     // const data = [].slice.call(row.cells).map(cell => cell.data)

      //     // message = message.join("");

      //     // message = message + "\n\nThe row data is:\n" + JSON.stringify(data)

      //     // alert(message)

      //     this.showAdminModal(true, index)
      //   }
      // })

    }
    this.getEsimList()
  },
  methods: {
    initSearchData() {
      this.searchProdCode = ""
      this.searchSimNum = ""
      this.searchStatus = ""
    }, 
    initEsimInfoData(){
      this.no = 0
      this.prodNo = 0
      this.prodOptNo = 0
      this.prodCode = ""
      this.prodName = ""
      this.simType = ""
      this.simNum = ""
      this.expireDate = ""
      this.actCode = ""
      this.status = ""
      this.statusName = ""
      this.changeStatus = ""
    },
    showEsimInfoModal(index){
      console.debug("showEsimInfoModal CALL")
      this.initEsimInfoData()

      const infoData = this.esimList[index]

      this.no = infoData.esim_no
      this.prodNo = infoData.prod_no
      this.prodOptNo = infoData.prod_opt_no
      this.prodCode = infoData.prod_code
      this.prodName = infoData.prod_name
      this.simType = ""
      this.simNum = infoData.sim_num
      this.expireDate = infoData.expire_date
      this.actCode = infoData.act_code
      this.status = infoData.status
      this.statusName = this.getStatusName(infoData.status)
      this.changeStatus = ""
      
      this.modalEsimInfo = new Modal(document.getElementById('modal-esim-info'))
      this.modalEsimInfo.show()
    },
    getEsimList() {
      // var vm = this;
      const param = {
        "searchProdCode": this.searchProdCode,
        "searchSimNum" : this.searchSimNum,
        "searchStatus" : this.searchStatus,
      }
      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/esim/list", param).then((response) => { // 실제 API를 요청한다/
        console.log(response.data.result);

        this.esimList = response.data.result

        this.esimList.forEach((item) => {
          item.sim_num = ""

          if(item.iccid !== "") {
            item.sim_num = item.iccid
          }
          else if(item.imsi !== "") {
            item.sim_num = item.imsi
          }
          else if(item.msisdn !== "") {
            item.sim_num = item.msisdn
          }
        })

        this.tabulator.setData(this.esimList);
      })
    },
    changeEsimStatus() {
      if(this.changeStatus === "") {
        alert("변경할 상태를 선택해주세요.");
        return;
      }

      if(this.status === this.changeStatus) {
        alert("현재상태와 변경할 상태가 동일합니다.");
        return;
      }

      if(!confirm("상태를 변경 하시겠습니까?")) {
        return;
      }

      const param = {
        no: this.no,
        prodNo: this.prodNo,
        prodOptNo: this.prodOptNo,
        changeStatus: this.changeStatus,
      }

      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/esim/changeStatus", param).then((response) => { // 실제 API를 요청한다/
        console.log(response.data.result);

        alert("eSIM 상태 변경이 완료되었습니다.");

        this.modalEsimInfo.hide()

        this.getEsimList()
      })


    },

    downloadQrcode() {
      const a = document.createElement("a");
      a.href =  this.$refs.qrCodeImage.imageUrl;
      a.download = this.$refs.qrCodeImage.downloadOptions.name + ".png";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },


    statusFormatter(cell) {
      const status = cell.getValue();

      return this.getStatusName(status);
    },

    qrFormatter() {

      return `<i class="fas fa-qrcode cursor-hand"></i>`;
    },

    shippedDateFormatter(cell) {
      const shipped_date = cell.getValue();

      return this.getDateString(shipped_date) + "<br>" + this.getTimeString(shipped_date);
    },

    activateDateFormatter(cell) {
      const activate_date = cell.getValue();

      return this.getDateString(activate_date) + "<br>" + this.getTimeString(activate_date);
    },

    refreshFormatter() {

      return `<i class="fas fa-sync-alt cursor-hand"></i>`;
    },

    qrClickHandeler(e, cell) {
      const data = cell.getRow().getData() 
      const esim_no = data.esim_no

      const idx = this.esimList.findIndex(x => x.esim_no == esim_no)

      this.showEsimInfoModal(idx)
    }

  },
};
</script>
