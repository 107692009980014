<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">상품 재고 현황</h5>
                <p class="mb-0 text-sm"></p>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <!-- <a
                    class="mb-0 btn btn-primary btn-sm"
                    target="_blank"
                    :disabled="auth==2"
                    @click="showProductModal(false)"
                    >+&nbsp; 상품코드 등록</a
                  > -->
                  <soft-button
                    color="primary"
                    :disabled="auth == 2"
                    @click="showProductModal(false)"
                    >+&nbsp; 상품코드 등록</soft-button
                  >
                  <!-- <soft-button
                    color="primary"
                    :disabled="auth == 2"
                    @click="showProductModal2(false)"
                    >+&nbsp; 상품코드 등록2</soft-button
                  > -->
                </div>
              </div>
            </div>
            <div class="">
              <form class="row row-cols-lg-auto g-3 align-items-end">
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>상품유형</label>
                    <select
                      id="admin-auth"
                      v-model="searchProdType"
                      class="form-control"
                    >
                      <option value="">전체</option>
                      <option value="ESIM">ESIM</option>
                      <option value="USIM">USIM</option>
                      <option value="WIFI">WIFI</option>
                    </select>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>사용플랫폼</label>
                    <div class="input-group mb-0">
                      <VueMultiselect
                        v-model="searchUsePlatform"
                        :options="optionsUsePlatform"
                        :multiple="true"
                        :taggable="true"
                      >
                      </VueMultiselect>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>상품코드</label>
                    <div class="input-group mb-0">
                      <input
                        v-model="searchProdCode"
                        type="text"
                        class="form-control"
                        placeholder="상품코드"
                        aria-label="상품코드"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>옵션코드</label>
                    <div class="input-group mb-0">
                      <input
                        v-model="searchOptCode"
                        type="text"
                        class="form-control"
                        placeholder="옵션코드"
                        aria-label="옵션코드"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>지역</label>
                    <select
                      id="admin-auth"
                      v-model="searchRegion"
                      class="form-control"
                    >
                      <option value="">전체</option>
                      <option value="글로벌">글로벌</option>
                      <option value="북미">북미</option>
                      <option value="중남미">중남미</option>
                      <option value="유럽">유럽</option>
                      <option value="한국">한국</option>
                      <option value="일본">일본</option>
                      <option value="중화권">중화권</option>
                      <option value="동남아">동남아</option>
                      <option value="아시아기타">아시아기타</option>
                      <option value="오세아니아">오세아니아</option>
                      <option value="아프리카">아프리카</option>
                    </select>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>상품명</label>
                    <div class="input-group mb-0">
                      <input
                        v-model="searchProdName"
                        type="text"
                        class="form-control"
                        placeholder="상품명"
                        aria-label="상품명"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <button
                    type="button"
                    class="btn btn-primary mb-0"
                    @click="getProductList()"
                  >
                    조회
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary mb-0 ms-1"
                    @click="searchInit()"
                  >
                    <span class="btn-inner--icon"
                      ><i class="fas fa-sync-alt"></i
                    ></span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive d-none">
              <table id="product-list" class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      순번
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      플랫폼
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      상품코드
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      옵션코드
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      지역
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      상품명
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      정상(만료임박)
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      판매
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      만료
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      취소
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in productList" :key="item">
                    <td class="text-center">
                      {{ i + 1 }}
                    </td>
                    <td
                      class="text-left platform-wrap"
                      v-html="getUsePlatform(item.use_platform)"
                    ></td>
                    <td class="text-left" style="cursor: pointer">
                      <span @click="showProductModal(true, i)">{{
                        item.prod_code
                      }}</span>
                    </td>
                    <td class="text-left">
                      {{ item.opt_code }}
                    </td>
                    <td class="">
                      {{ item.region }}
                    </td>
                    <td class="">
                      {{ item.prod_name }}
                    </td>
                    <td class="">
                      {{ item.is_opt == "0" ? item.ready + item.imminent : ""
                      }}{{
                        item.is_opt == "0" ? "(" + item.imminent + ")" : ""
                      }}
                    </td>
                    <td class="">
                      {{ item.shipped + item.activated }}
                    </td>
                    <td class="">
                      {{ item.is_opt == "0" ? item.expired : "" }}
                    </td>
                    <td class="">
                      {{ item.is_opt == "0" ? item.canceled : "" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div ref="table"></div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="modal-product"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modal-admin"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="card card-plain">
              <div class="card-header pb-0 text-left">
                <h3 class="font-weight-bolder text-primary text-gradient">
                  {{ modalTitle }}
                </h3>
              </div>
              <div class="card-body">
                <form role="form text-left" class="row" @submit.prevent>
                  <div class="col-12">
                    <div v-if="no != 0" class="text-right">
                      <soft-button
                        class="float-end"
                        color="danger"
                        size="sm"
                        :disabled="auth == 2"
                        @click="deleteProduct()"
                        >삭제</soft-button
                      >
                    </div>
                  </div>
                  <div v-if="prodType == 'ESIM'" class="col-12">
                    <div class="form-check form-switch">
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckDefault"
                        >옵션상품</label
                      >
                      <input
                        id="flexSwitchCheckDefault"
                        v-model="isOpt"
                        class="form-check-input"
                        type="checkbox"
                        :disabled="modalModfy"
                      />
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label>상품유형</label>
                      <select
                        id="esim-type"
                        v-model="prodType"
                        class="form-control"
                      >
                        <option value="ESIM">ESIM</option>
                        <option value="USIM">USIM</option>
                        <option value="WIFI">WIFI</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>사용플랫폼</label>
                      <div class="input-group mb-3">
                        <VueMultiselect
                          v-model="usePlatform"
                          :options="optionsUsePlatform"
                          :multiple="true"
                          :taggable="true"
                        >
                        </VueMultiselect>
                      </div>
                    </div>
                  </div>
                  <div class="col-5">
                    <div class="form-group">
                      <label>상품코드</label>
                      <div class="input-group mb-3">
                        <input
                          v-if="!isOpt"
                          v-model="prodCode"
                          type="text"
                          class="form-control"
                          placeholder="상품코드"
                          aria-label="상품코드"
                          :disabled="modalModfy"
                        />
                        <button
                          v-if="!modalModfy && !isOpt"
                          id="button-addon2"
                          class="btn btn-outline-primary mb-0"
                          type="button"
                          :disabled="btnProdCodeCheckDisabled"
                          @click="checkProdCode()"
                        >
                          중복확인
                        </button>
                        <select
                          v-if="isOpt"
                          v-model="prodCode"
                          class="form-control"
                          :disabled="modalModfy"
                        >
                          <option value="">=상품코드선택=</option>
                          <option
                            v-for="item in prodCodeList"
                            :key="item"
                            :value="item.prod_code"
                          >
                            {{ item.prod_code }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div v-if="isOpt" class="form-group">
                      <label>옵션코드</label>
                      <div class="input-group mb-3">
                        <input
                          v-model="optCode"
                          type="text"
                          class="form-control"
                          placeholder="옵션코드"
                          aria-label="옵션코드"
                          :disabled="modalModfy"
                        />
                        <button
                          v-if="!modalModfy && isOpt"
                          id="button-addon2"
                          class="btn btn-outline-primary mb-0"
                          type="button"
                          :disabled="btnOptCodeCheckDisabled"
                          @click="checkOptCode()"
                        >
                          중복확인
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div v-if="checkPlatformGyg" class="form-group">
                      <label>GYG Option ID</label>
                      <div class="input-group mb-3">
                        <input
                          v-model="gygOptionId"
                          type="text"
                          class="form-control"
                          placeholder="GYG Option ID"
                          aria-label="GYG Option ID"
                          :disabled="modalModfy"
                        />
                        <button
                          v-if="!modalModfy"
                          id="button-addon2"
                          class="btn btn-outline-primary mb-0"
                          type="button"
                          :disabled="btnGygOptionIdCheckDisabled"
                          @click="checkGygOptionId()"
                        >
                          중복확인
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>상품명</label>
                      <div class="input-group mb-3">
                        <input
                          v-model="prodName"
                          type="text"
                          class="form-control"
                          placeholder="상품명"
                          aria-label="상품명"
                        />
                      </div>
                    </div>
                  </div>
                  <div v-if="prodType == 'ESIM'" class="col-6">
                    <div class="form-group">
                      <label for="esim-type">ESIM유형</label>
                      <select
                        id="esim-type"
                        v-model="esimType"
                        class="form-control"
                      >
                        <option value="LPA">LPA</option>
                        <option value="REDEEM">REDEEM</option>
                        <option value="DISCOVERY">DISCOVERY</option>
                      </select>
                    </div>
                  </div>
                  <div v-if="prodType == 'ESIM'" class="col-6">
                    <div class="form-group">
                      <label for="is-roaming">로밍여부</label>
                      <select
                        id="is-roaming"
                        v-model="isRoaming"
                        class="form-control"
                      >
                        <option value="1">O</option>
                        <option value="0">X</option>
                      </select>
                    </div>
                  </div>
                  <div v-if="prodType == 'ESIM'" class="col-6">
                    <div class="form-group">
                      <label for="is-fup">Fair Use Policy</label>
                      <select id="is-fup" v-model="isFup" class="form-control">
                        <option value="1">저속무제한</option>
                        <option value="0">데이터차단</option>
                      </select>
                    </div>
                  </div>
                  <div v-if="prodType == 'ESIM'" class="col-6">
                    <div class="form-group">
                      <label>APN</label>
                      <div class="input-group mb-3">
                        <input
                          v-model="apn"
                          type="text"
                          class="form-control"
                          placeholder="APN"
                          aria-label="APN"
                        />
                      </div>
                    </div>
                  </div>
                  <div v-if="prodType == 'ESIM'" class="col-6">
                    <div class="form-group">
                      <label for="is-24h">이용일기준(1일)</label>
                      <select id="is-24h" v-model="is24h" class="form-control">
                        <option value="1">사용시작후24시간</option>
                        <option value="0">기준지역자정</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="admin-auth">지역</label>
                      <select
                        id="admin-auth"
                        v-model="region"
                        class="form-control"
                      >
                        <option value="글로벌">글로벌</option>
                        <option value="북미">북미</option>
                        <option value="중남미">중남미</option>
                        <option value="유럽">유럽</option>
                        <option value="한국">한국</option>
                        <option value="일본">일본</option>
                        <option value="중화권">중화권</option>
                        <option value="동남아">동남아</option>
                        <option value="아시아기타">아시아기타</option>
                        <option value="오세아니아">오세아니아</option>
                        <option value="아프리카">아프리카</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="admin-auth">공급자</label>
                      <input
                        v-model="suppCode"
                        type="text"
                        class="form-control"
                        placeholder="공급자 입력"
                        aria-label="공급자 입력"
                      />
                    </div>
                  </div>
                  <div v-if="prodType == 'ESIM'" class="col-6"></div>
                  <div v-if="prodType == 'ESIM'" class="col-6">
                    <div class="form-check form-switch">
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckHasApi"
                        >API 연동상품</label
                      >
                      <input
                        id="flexSwitchCheckHasApi"
                        v-model="hasApi"
                        class="form-check-input"
                        type="checkbox"
                      />
                    </div>
                  </div>
                  <div v-if="prodType == 'ESIM'" class="col-6">
                    <div v-if="hasApi" class="form-group">
                      <label for="pkg-id">패키지 ID</label>
                      <input
                        id="pkg-id"
                        v-model="pkgId"
                        type="text"
                        class="form-control"
                        placeholder="패키지 ID 입력"
                        aria-label="패키지 ID 입력"
                      />
                    </div>
                  </div>

                  <div v-show="prodType != 'ESIM'" class="col-6">
                    <div class="form-group">
                      <label for="admin-auth">바우처 헤더 로고</label>
                      <input
                        v-model="usimVoucherHeaderLogo"
                        type="text"
                        class="form-control"
                        placeholder="바우처 헤더 로고"
                        aria-label="바우처 헤더 로고"
                      />
                      <!-- <div v-show="usimVoucherHeaderLogo === ''">
                        <input
                          ref="refUsimVoucherHeaderLog"
                          type="file"
                          accept="image/*"
                          class="form-control"
                          placeholder="바우처 헤더 로고"
                          aria-label="바우처 헤더 로고"
                          @change="usimVoucherHeaderLogoHandleFileUpload"
                        />
                      </div>
                      <div v-show="usimVoucherHeaderLogo !== ''">
                        <div class="d-flex">
                          <soft-button
                            class="me-2"
                            color="info"
                            @click="showPreviewModal(usimVoucherHeaderLogo)"
                            >미리보기</soft-button
                          >
                          <soft-button
                            color="danger"
                            @click="removeUsimVoucherHeaderLogo()"
                            >삭제</soft-button
                          >
                        </div>
                      </div> -->
                    </div>
                  </div>
                  <div v-if="prodType != 'ESIM'" class="col-6">
                    <div class="form-group">
                      <label for="admin-auth">바우처 이용가이드 URL</label>
                      <input
                        v-model="usimVoucherGuideUrl"
                        type="text"
                        class="form-control"
                        placeholder="바우처 이용가이드 URL"
                        aria-label="바우처 이용가이드 URL"
                      />
                    </div>
                  </div>
                  <div v-if="prodType != 'ESIM'" class="col-6">
                    <div class="form-group">
                      <label for="admin-auth">바우처 헤더 배경색상</label>
                      <input
                        v-model="usimVoucherHeaderBackgroundColor"
                        type="color"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div v-if="prodType != 'ESIM'" class="col-6">
                    <div class="form-group">
                      <label for="admin-auth">바우처 포인트색상</label>
                      <input
                        v-model="usimVoucherPointColor"
                        type="color"
                        class="form-control"
                      />
                    </div>
                  </div>

                  <div v-if="prodType != 'ESIM'" class="col-6">
                    <div class="form-group">
                      <label for="partner-no">파트너사</label>
                      <select
                        id="partner-no"
                        v-model="partnerNo"
                        class="form-control"
                      >
                        <option value="0">=선택안함=</option>
                        <option
                          v-for="item in partnerList"
                          :key="item"
                          :value="item.no"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div
                    v-if="prodType != 'ESIM' && partnerNo != '0'"
                    class="col-6"
                  >
                    <div class="form-group">
                      <label for="partner-no">&nbsp;</label>
                      <div class="form-check form-switch">
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckPartnerMailSend"
                          >파트너사 메일발송</label
                        >
                        <input
                          id="flexSwitchCheckPartnerMailSend"
                          v-model="partnerMailSend"
                          class="form-check-input"
                          type="checkbox"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="form-group">
                      <label for="notice">주의사항</label>
                      <textarea
                        id="notice"
                        v-model="notice"
                        class="form-control"
                        placeholder="주의사항 입력"
                        aria-label="주의사항 입력"
                        rows="5"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="text-center">
                      <div class="row">
                        <div class="col-sm">
                          <soft-button
                            color="primary"
                            full-width="true"
                            :disabled="auth == 2"
                            @click="saveProduct()"
                            >{{ btnSaveText }}</soft-button
                          >
                        </div>
                        <div class="col-sm">
                          <soft-button
                            color="secondary"
                            full-width="true"
                            @click="modalProduct.hide()"
                            >취소</soft-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="modal-product-2"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modal-admin"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="card card-plain">
              <div class="card-header pb-0 text-left">
                <h3 class="font-weight-bolder text-primary text-gradient">
                  {{ modalTitle }}
                </h3>
              </div>
              <div class="card-body">
                <div class="card">
                  <div class="card">
                    <div class="card-header">Featured</div>
                    <div class="card-body">
                      <h5 class="card-title">Special title treatment</h5>
                      <p class="card-text">
                        With supporting text below as a natural lead-in to
                        additional content.
                      </p>
                      <a href="#" class="btn btn-primary">Go somewhere</a>
                    </div>
                  </div>
                </div>
                <form role="form text-left" class="row" @submit.prevent>
                  <div class="col-12">
                    <div v-if="no != 0" class="text-right">
                      <soft-button
                        class="float-end"
                        color="danger"
                        size="sm"
                        :disabled="auth == 2"
                        @click="deleteProduct()"
                        >삭제</soft-button
                      >
                    </div>
                  </div>
                  <div v-if="prodType == 'ESIM'" class="col-12">
                    <div class="form-check form-switch">
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckDefault"
                        >옵션상품</label
                      >
                      <input
                        id="flexSwitchCheckDefault"
                        v-model="isOpt"
                        class="form-check-input"
                        type="checkbox"
                        :disabled="modalModfy"
                      />
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label>상품유형</label>
                      <select
                        id="esim-type"
                        v-model="prodType"
                        class="form-control"
                      >
                        <option value="ESIM">ESIM</option>
                        <option value="USIM">USIM</option>
                        <option value="WIFI">WIFI</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>사용플랫폼</label>
                      <div class="input-group mb-3">
                        <VueMultiselect
                          v-model="usePlatform"
                          :options="optionsUsePlatform"
                          :multiple="true"
                          :taggable="true"
                        >
                        </VueMultiselect>
                      </div>
                    </div>
                  </div>
                  <div class="col-5">
                    <div class="form-group">
                      <label>상품코드</label>
                      <div class="input-group mb-3">
                        <input
                          v-if="!isOpt"
                          v-model="prodCode"
                          type="text"
                          class="form-control"
                          placeholder="상품코드"
                          aria-label="상품코드"
                          :disabled="modalModfy"
                        />
                        <button
                          v-if="!modalModfy && !isOpt"
                          id="button-addon2"
                          class="btn btn-outline-primary mb-0"
                          type="button"
                          :disabled="btnProdCodeCheckDisabled"
                          @click="checkProdCode()"
                        >
                          중복확인
                        </button>
                        <select
                          v-if="isOpt"
                          v-model="prodCode"
                          class="form-control"
                          :disabled="modalModfy"
                        >
                          <option value="">=상품코드선택=</option>
                          <option
                            v-for="item in prodCodeList"
                            :key="item"
                            :value="item.prod_code"
                          >
                            {{ item.prod_code }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div v-if="isOpt" class="form-group">
                      <label>옵션코드</label>
                      <div class="input-group mb-3">
                        <input
                          v-model="optCode"
                          type="text"
                          class="form-control"
                          placeholder="옵션코드"
                          aria-label="옵션코드"
                          :disabled="modalModfy"
                        />
                        <button
                          v-if="!modalModfy && isOpt"
                          id="button-addon2"
                          class="btn btn-outline-primary mb-0"
                          type="button"
                          :disabled="btnOptCodeCheckDisabled"
                          @click="checkOptCode()"
                        >
                          중복확인
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div v-if="checkPlatformGyg" class="form-group">
                      <label>GYG Option ID</label>
                      <div class="input-group mb-3">
                        <input
                          v-model="gygOptionId"
                          type="text"
                          class="form-control"
                          placeholder="GYG Option ID"
                          aria-label="GYG Option ID"
                          :disabled="modalModfy"
                        />
                        <button
                          v-if="!modalModfy"
                          id="button-addon2"
                          class="btn btn-outline-primary mb-0"
                          type="button"
                          :disabled="btnGygOptionIdCheckDisabled"
                          @click="checkGygOptionId()"
                        >
                          중복확인
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>상품명</label>
                      <div class="input-group mb-3">
                        <input
                          v-model="prodName"
                          type="text"
                          class="form-control"
                          placeholder="상품명"
                          aria-label="상품명"
                        />
                      </div>
                    </div>
                  </div>
                  <div v-if="prodType == 'ESIM'" class="col-6">
                    <div class="form-group">
                      <label for="esim-type">ESIM유형</label>
                      <select
                        id="esim-type"
                        v-model="esimType"
                        class="form-control"
                      >
                        <option value="BASIC">BASIC</option>
                        <option value="REDEEM">REDEEM</option>
                        <option value="EID">EID</option>
                      </select>
                    </div>
                  </div>
                  <div v-if="prodType == 'ESIM'" class="col-6">
                    <div class="form-group">
                      <label for="is-roaming">로밍여부</label>
                      <select
                        id="is-roaming"
                        v-model="isRoaming"
                        class="form-control"
                      >
                        <option value="1">O</option>
                        <option value="0">X</option>
                      </select>
                    </div>
                  </div>
                  <div v-if="prodType == 'ESIM'" class="col-6">
                    <div class="form-group">
                      <label for="is-fup">Fair Use Policy</label>
                      <select id="is-fup" v-model="isFup" class="form-control">
                        <option value="1">저속무제한</option>
                        <option value="0">데이터차단</option>
                      </select>
                    </div>
                  </div>
                  <div v-if="prodType == 'ESIM'" class="col-6">
                    <div class="form-group">
                      <label>APN</label>
                      <div class="input-group mb-3">
                        <input
                          v-model="apn"
                          type="text"
                          class="form-control"
                          placeholder="APN"
                          aria-label="APN"
                        />
                      </div>
                    </div>
                  </div>
                  <div v-if="prodType == 'ESIM'" class="col-6">
                    <div class="form-group">
                      <label for="is-24h">이용일기준(1일)</label>
                      <select id="is-24h" v-model="is24h" class="form-control">
                        <option value="1">사용시작후24시간</option>
                        <option value="0">기준지역자정</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="admin-auth">지역</label>
                      <select
                        id="admin-auth"
                        v-model="region"
                        class="form-control"
                      >
                        <option value="글로벌">글로벌</option>
                        <option value="북미">북미</option>
                        <option value="중남미">중남미</option>
                        <option value="유럽">유럽</option>
                        <option value="한국">한국</option>
                        <option value="일본">일본</option>
                        <option value="중화권">중화권</option>
                        <option value="동남아">동남아</option>
                        <option value="아시아기타">아시아기타</option>
                        <option value="오세아니아">오세아니아</option>
                        <option value="아프리카">아프리카</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="admin-auth">공급자</label>
                      <input
                        v-model="suppCode"
                        type="text"
                        class="form-control"
                        placeholder="공급자 입력"
                        aria-label="공급자 입력"
                      />
                    </div>
                  </div>
                  <div v-if="prodType == 'ESIM'" class="col-6"></div>
                  <div v-if="prodType == 'ESIM'" class="col-6">
                    <div class="form-check form-switch">
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckHasApi"
                        >API 연동상품</label
                      >
                      <input
                        id="flexSwitchCheckHasApi"
                        v-model="hasApi"
                        class="form-check-input"
                        type="checkbox"
                      />
                    </div>
                  </div>
                  <div v-if="prodType == 'ESIM'" class="col-6">
                    <div v-if="hasApi" class="form-group">
                      <label for="pkg-id">패키지 ID</label>
                      <input
                        id="pkg-id"
                        v-model="pkgId"
                        type="text"
                        class="form-control"
                        placeholder="패키지 ID 입력"
                        aria-label="패키지 ID 입력"
                      />
                    </div>
                  </div>

                  <div v-if="prodType != 'ESIM'" class="col-6">
                    <div class="form-group">
                      <label for="partner-no">파트너사</label>
                      <select
                        id="partner-no"
                        v-model="partnerNo"
                        class="form-control"
                      >
                        <option value="0">=선택안함=</option>
                        <option
                          v-for="item in partnerList"
                          :key="item"
                          :value="item.no"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div
                    v-if="prodType != 'ESIM' && partnerNo != '0'"
                    class="col-6"
                  >
                    <div class="form-group">
                      <label for="partner-no">&nbsp;</label>
                      <div class="form-check form-switch">
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckPartnerMailSend"
                          >파트너사 메일발송</label
                        >
                        <input
                          id="flexSwitchCheckPartnerMailSend"
                          v-model="partnerMailSend"
                          class="form-check-input"
                          type="checkbox"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="form-group">
                      <label for="notice">주의사항</label>
                      <textarea
                        id="notice"
                        v-model="notice"
                        class="form-control"
                        placeholder="주의사항 입력"
                        aria-label="주의사항 입력"
                        rows="5"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="text-center">
                      <div class="row">
                        <div class="col-sm">
                          <soft-button
                            color="primary"
                            full-width="true"
                            :disabled="auth == 2"
                            @click="saveProduct()"
                            >{{ btnSaveText }}</soft-button
                          >
                        </div>
                        <div class="col-sm">
                          <soft-button
                            color="secondary"
                            full-width="true"
                            @click="modalProduct2.hide()"
                            >취소</soft-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="modal-preview"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modal-preview"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="card card-plain">
              <div class="card-header pb-0 text-left">
                <h3 class="font-weight-bolder text-primary text-gradient">
                  바우처 헤더 로고
                </h3>
              </div>
              <div class="card-body">
                <div class="p-2">
                  <img :src="previewUrl" />
                </div>
                <div class="">
                  <soft-button
                    color="secondary"
                    full-width="true"
                    @click="modalPreview.hide()"
                    >취소</soft-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import SoftButton from "@/components/SoftButton.vue";
import VueMultiselect from "vue-multiselect";

import { TabulatorFull as Tabulator } from "tabulator-tables";

export default {
  name: "ProductStock",
  components: {
    SoftButton,
    VueMultiselect,
  },
  data() {
    return {
      searchUsePlatform: [],
      optionsUsePlatform: ["CAFE24", "GYG", "USIMSTORE"],
      optionspartnerMailList: [],

      searchProdType: "",
      searchProdCode: "",
      searchOptCode: "",
      searchRegion: "",
      searchProdName: "",

      modalModfy: false, //
      productList: [],
      prodCodeList: [],

      partnerList: [],

      btnSaveText: "상품코드 등록",
      modalTitle: "상품코드 등록",

      no: 0,
      isOpt: 0,
      prodType: "ESIM",
      prodCode: "",
      optCode: "",
      gygOptionId: "",
      prodName: "",
      region: "",
      suppCode: "",
      hasApi: 0,
      pkgId: "",
      usePlatform: [],
      esimType: "BASIC",
      isRoaming: 0,
      isFup: 0,
      apn: "",
      is24h: 0,
      notice: "",
      partnerMailSend: 0,
      partnerNo: "0",

      btnProdCodeCheckDisabled: false,
      btnOptCodeCheckDisabled: false,
      btnGygOptionIdCheckDisabled: false,

      prodCodeCheck: false,
      optCodeCheck: false,
      gygOptionIdCheck: false,

      usimVoucherHeaderLogo: "",
      usimVoucherHeaderLogoBase64: "",
      usimVoucherHeaderLogoExtension: "",
      usimVoucherHeaderBackgroundColor: "",
      usimVoucherPointColor: "",
      usimVoucherGuideUrl: "",

      auth: 0,

      tabulator: null, //variable to hold your table

      previewUrl: "",
    };
  },
  computed: {
    getUsePlatform() {
      return (usePlatform) => {
        const platform = usePlatform;
        let htmlUsePlatform = "";
        platform.forEach((item) => {
          let bClass = "badge-secondary";
          if (item === "CAFE24") {
            bClass = "badge-info";
          } else if (item === "GYG") {
            bClass = "badge-success";
          } else if (item === "USIMSTORE") {
            bClass = "badge-primary";
          }

          htmlUsePlatform += `<span class="platform-item badge ${bClass}">${item.substr(
            0,
            1
          )}</span>`;
        });

        return htmlUsePlatform;
      };
    },
    checkPlatformGyg() {
      let returnValue = this.usePlatform.indexOf("GYG") > -1;
      return returnValue;
    },
  },
  watch: {
    prodCode(value, oldValue) {
      if (!this.modalModfy && value !== oldValue) {
        this.btnProdCodeCheckDisabled = false;
        this.prodCodeCheck = false;
      }
    },
    optCode(value, oldValue) {
      if (!this.modalModfy && value !== oldValue) {
        this.btnOptCodeCheckDisabled = false;
        this.optCodeCheck = false;
      }
    },
    gygOptioId(value, oldValue) {
      if (!this.modalModfy && value !== oldValue) {
        this.btnGygOptionIdCheckDisabled = false;
        this.gygOptionIdCheck = false;
      }
    },
  },
  mounted() {
    //instantiate Tabulator when element is mounted
    this.tabulator = new Tabulator(this.$refs.table, {
      data: this.productList, //link data to table
      reactiveData: true, //enable data reactivity
      renderHorizontal: "virtual",
      pagination: "local",
      paginationSize: 50,
      paginationSizeSelector: [25, 50, 100],
      paginationCounter: "rows",
      columns: [
        { title: "순번", formatter: "rownum" },
        { title: "상품유형", field: "prod_type" },
        {
          title: "플랫품",
          field: "use_platform",
          hozAlign: "left",
          formatter: this.useFlatformFormatter,
        },
        { title: "상품코드", field: "prod_code", width: 250 },
        { title: "옵션코드", field: "opt_code", width: 250 },
        { title: "지역", field: "region" },
        { title: "상품명", field: "prod_name" },
        {
          title: "정상(만료임박)",
          field: "ready",
          formatter: this.cntFormatter,
          formatterParams: "ready",
        },
        {
          title: "판매",
          field: "shipped",
          formatter: this.cntFormatter,
          formatterParams: "shipped",
        },
        {
          title: "만료",
          field: "expired",
          formatter: this.cntFormatter,
          formatterParams: "expired",
        },
        {
          title: "취소",
          field: "canceled",
          formatter: this.cntFormatter,
          formatterParams: "canceled",
        },
      ], //define table columns
    });
    const thisObj = this;
    this.tabulator.on("rowClick", function (e, row) {
      const data = row.getData();
      const no = data.no;

      const idx = thisObj.productList.findIndex((x) => x.no == no);

      thisObj.showProductModal(true, idx);
    });
    const userInfo = this.$cookies.get("userInfo");
    this.auth = userInfo.auth;
    if (document.getElementById("product-list")) {
      // const myData = {
      //   headdings: [
      //     "no",
      //     "prod_code",
      //     "opt_code"
      //   ]
      // }
      // this.productDataTable = new DataTable("#product-list", {
      //   searchable: false,
      //   fixedHeight: false,
      //   perPage: 10,
      //   // data: this.productList,
      // });
      // this.productDataTable.body.addEventListener("click", e => {
      //   console.log("DataTable CLick Event ");
      //   console.log(e.target);
      //   console.log(e);
      //   console.log(e.target.getAttribute("data-id"));
      //   console.log(e.target.getAttribute("data-type"));
      //   if (e.target.getAttribute("data-type") === "EDIT") {
      //     const index = parseInt(e.target.getAttribute("data-index"), 10)
      //     // const row = adminDataTable.activeRows[index]
      //     // let message = [
      //     //   "This is row ",
      //     //   (row.rowIndex),
      //     //   " of ",
      //     //   adminDataTable.options.perPage,
      //     //   " rendered rows and row ",
      //     //   (index + 1),
      //     //   " of ",
      //     //   adminDataTable.data.length,
      //     //   " total rows."
      //     // ]
      //     // console.log(adminDataTable.data);
      //     // const data = [].slice.call(row.cells).map(cell => cell.data)
      //     // message = message.join("");
      //     // message = message + "\n\nThe row data is:\n" + JSON.stringify(data)
      //     // alert(message)
      //     this.showProductModal(true, index)
      //   }
      // })
    }
    this.getPartnerList();
    this.getProdInfoAll();
    this.getProductList();
  },
  methods: {
    searchInit() {
      this.searchProdCode = "";
      this.searchOptCode = "";
      this.searchRegion = "";
      this.searchProdName = "";
    },
    getPartnerList() {
      this.$axios
        .post(process.env.VUE_APP_BACKEND_URL + "api/partner/list")
        .then((response) => {
          // 실제 API를 요청한다/
          console.log(response.data.result);

          this.partnerList = response.data.result;
        });
    },
    getProdInfoAll() {
      this.$axios
        .post(process.env.VUE_APP_BACKEND_URL + "api/product/prodCodeList")
        .then((response) => {
          // 실제 API를 요청한다/
          console.log(response.data.result);

          this.prodCodeList = response.data.result;
        });
    },
    showProductModal(modify, index) {
      console.debug("showAdminModal CALL", index);
      this.initData();

      this.modalModfy = modify;

      if (modify) {
        this.btnSaveText = "상품코드 수정";
        this.modalTitle = "상품코드 수정";

        this.no = this.productList[index].no;
        this.isOpt = this.productList[index].is_opt ? true : false;
        this.prodType = this.productList[index].prod_type;
        this.prodCode = this.productList[index].prod_code;
        this.optCode = this.productList[index].opt_code;
        this.gygOptionId = this.productList[index].gyg_option_id;
        this.prodName = this.productList[index].prod_name;
        this.region = this.productList[index].region;
        this.suppCode = this.productList[index].supp_code;
        this.hasApi = this.productList[index].has_api ? true : false;
        this.pkgId = this.productList[index].pkg_id;
        this.usePlatform = this.productList[index].use_platform;

        this.esimType = this.productList[index].esim_type;
        this.isRoaming = this.productList[index].is_roaming;
        this.isFup = this.productList[index].is_fup;
        this.apn = this.productList[index].apn;
        this.is24h = this.productList[index].is_24h;
        this.notice = this.productList[index].notice;

        this.partnerNo = this.productList[index].partner_no;
        this.partnerMailSend = this.productList[index].partner_mail_send
          ? true
          : false;

        this.usimVoucherHeaderLogo = this.productList[
          index
        ].usim_voucher_header_logo;
        this.usimVoucherHeaderBackgroundColor = this.productList[
          index
        ].usim_voucher_header_background_color;
        this.usimVoucherPointColor = this.productList[
          index
        ].usim_voucher_point_color;
        this.usimVoucherGuideUrl = this.productList[
          index
        ].usim_voucher_guide_url;

        this.btnProdCodeCheckDisabled = true;
        this.btnOptCodeCheckDisabled = true;
        this.btnGygOptionIdCheckDisabled = true;
        this.prodCodeCheck = true;
        this.optCodeCheck = true;
        this.gygOptionIdCheck = true;
      }

      this.modalProduct = new Modal(document.getElementById("modal-product"));
      this.modalProduct.show();
    },
    showProductModal2(modify, index) {
      console.debug("showAdminModal CALL", index);
      this.modalProduct2 = new Modal(
        document.getElementById("modal-product-2")
      );
      this.modalProduct2.show();
    },
    showPreviewModal(src) {
      console.debug("showPreviewModal CALL", src);
      this.previewUrl = "https://192.168.0.156:3899/static/usimVoucher/" + src;
      this.modalPreview = new Modal(document.getElementById("modal-preview"));
      this.modalPreview.show();
    },
    getProductList() {
      // this.productDataTable.clear();
      // this.productDataTable.data = [];

      const param = {
        searchProdType: this.searchProdType,
        searchProdCode: this.searchProdCode,
        searchOptCode: this.searchOptCode,
        searchRegion: this.searchRegion,
        searchProdName: this.searchProdName,
        searchUsePlatform: this.searchUsePlatform,
      };
      this.$axios
        .post(process.env.VUE_APP_BACKEND_URL + "api/product/list", param)
        .then((response) => {
          // 실제 API를 요청한다/
          console.log(response.data.result);

          this.productList = response.data.result;

          this.tabulator.setData(this.productList);

          // let data = this.productList
          // let obj = {
          //     // Quickly get the headings
          //     headings: Object.keys(data[0]),

          //     // data array
          //     data
          // };

          // // Loop over the objects to get the values
          // for ( let i = 0; i < data.length; i++ ) {

          //     obj.data[i] = [];

          //     for (let p in data[i]) {
          //         // eslint-disable-next-line no-prototype-builtins
          //         if( data[i].hasOwnProperty(p) ) {
          //             console.log(data[i][p])
          //             const d = data[i][p]!==null?data[i][p]+"":""
          //             obj.data[i].push(d);
          //         }
          //     }
          // }

          // console.log(obj)

          // this.productDataTable.insert(obj)

          //         let newData = {
          //             headings: [
          //                 "Name",
          //                 "Position",
          //                 "Town",
          //                 "Ext.",
          //                 "Start Date",
          //                 "Salary"
          //             ],
          //       data: [
          //         [
          //                 "Cedric Kelly",
          //                 "Senior Javascript Developer",
          //                 "Edinburgh",
          //                 "6224",
          //                 "2012/03/29",
          //                 "$433,060"
          //             ],
          //             [
          //                 "Airi Satou",
          //                 "Accountant",
          //                 "Tokyo",
          //                 "5407",
          //                 "2008/11/28",
          //                 "$162,700"
          //             ],
          //       ]
          // };
          // this.productDataTable.insert(newData)

          // this.movieList = response.data.response.body.items.item; // 받아온 데이터를 movieList 배열에 넣어준다.
          // console.log('영화목록 : ' + this.movieList);
        });
    },
    initData() {
      this.btnSaveText = "상품코드 추가";
      this.modalTitle = "상품코드 추가";

      this.no = 0;
      this.isOpt = 0;
      this.prodType = "ESIM";
      this.prodCode = "";
      this.optCode = "";
      this.gygOptionId = "";
      this.prodName = "";
      this.region = "";
      this.suppCode = "";
      this.hasApi = 0;
      this.pkgId = "";
      this.usePlatform = [];
      this.esimType = "BASIC";
      this.isRoaming = 0;
      this.isFup = 0;
      this.apn = "";
      this.is24h = 0;
      this.notice = "";

      this.usimVoucherHeaderLogo = "";
      this.usimVoucherHeaderLogoBase64 = "";
      this.usimVoucherHeaderLogoExtension = "";
      this.usimVoucherHeaderBackgroundColor = "";
      this.usimVoucherPointColor = "";
      this.usimVoucherGuideUrl = "";

      this.partnerNo = "0";
      this.partnerMailSend = 0;

      this.btnProdCodeCheckDisabled = false;
      this.btnOptCodeCheckDisabled = false;
      this.btnGygOptionIdCheckDisabled = false;

      this.prodCodeCheck = false;
      this.optCodeCheck = false;
      this.gygOptionCheck = false;
    },
    saveProduct() {
      if (this.usePlatform.length === 0) {
        alert("사용 플랫폼을 선택해주세요.");
        return;
      }

      if (!this.modalModfy && this.prodCode === "") {
        alert("상품코드를 입력해주세요.");
        return;
      }

      if (!this.modalModfy && !this.prodCodeCheck && !this.isOpt) {
        alert("상품코드 중복확인을 해주세요.");
        return;
      }

      if (this.modalModfy && this.prodCode === "") {
        alert("상품코드를 선택해주세요.");
        return;
      }

      if (this.isOpt && this.optCode === "" && !this.modalModfy) {
        alert("옵션코드를 입력해주세요.");
        return;
      }

      if (!this.modalModfy && !this.optCodeCheck && this.isOpt) {
        alert("옵션코드 중복확인을 해주세요.");
        return;
      }

      if (
        !this.modalModfy &&
        this.checkPlatformGyg &&
        this.gygOptionId === ""
      ) {
        alert("GYG Option ID를 입력해주세요.");
        return;
      }

      if (!this.modalModfy && this.checkPlatformGyg && !this.gygOptionIdCheck) {
        alert("GYG Option ID 중복확인을 해주세요.");
        return;
      }

      if (this.prodName === "") {
        alert("상품명을 입력해주세요.");
        return;
      }

      if (this.region === "") {
        alert("지역을 선택해주세요.");
        return;
      }

      if (this.suppCode === "") {
        alert("공급자를 선택해주세요.");
        return;
      }

      if (this.hasApi && this.pkgId === "") {
        alert("패키지ID를 입력해주세요.");
        return;
      }

      const param = {
        no: this.no,
        isOpt: this.isOpt ? 1 : 0,
        prodType: this.prodType,
        prodCode: this.prodCode,
        optCode: this.optCode,
        gygOptionId: this.gygOptionId,
        prodName: this.prodName,
        region: this.region,
        suppCode: this.suppCode,
        hasApi: this.hasApi ? 1 : 0,
        pkgId: this.pkgId,
        usePlatform: this.usePlatform,
        esimType: this.esimType,
        isRoaming: this.isRoaming,
        isFup: this.isFup,
        apn: this.apn,
        is24h: this.is24h,
        notice: this.notice,
        partnerNo: this.partnerNo,
        partnerMailSend: this.partnerMailSend ? 1 : 0,
        usimVoucherHeaderLogo: this.usimVoucherHeaderLogo,
        // usimVoucherHeaderLogo: this.usimVoucherHeaderLogoBase64
        //   ? this.makeUsimVoucherHeaderLogoFileName()
        //   : "",
        usimVoucherHeaderLogoBase64: this.usimVoucherHeaderLogoBase64,
        usimVoucherHeaderBackgroundColor: this.usimVoucherHeaderBackgroundColor,
        usimVoucherPointColor: this.usimVoucherPointColor,
        usimVoucherGuideUrl: this.usimVoucherGuideUrl,
      };

      const modeUri = this.modalModfy ? "update" : "insert";
      const modeText = this.modalModfy ? "수정" : "등록";

      this.$axios
        .post(process.env.VUE_APP_BACKEND_URL + "api/product/" + modeUri, param)
        .then((response) => {
          // 실제 API를 요청한다/
          console.log(response.data.result);

          alert("정보 " + modeText + "이 완료되었습니다.");

          this.modalProduct.hide();

          this.getProdInfoAll();
          this.getProductList();
        });
    },

    deleteProduct() {
      if (!confirm("선택하신 상품을 삭제하시겠습니까?")) {
        return;
      }

      const param = {
        no: this.no,
      };

      this.$axios
        .post(process.env.VUE_APP_BACKEND_URL + "api/product/delete", param)
        .then((response) => {
          // 실제 API를 요청한다/
          console.log(response.data.result);

          alert("상품 삭제가 완료되었습니다.");

          this.modalProduct.hide();

          this.getProdInfoAll();
          this.getProductList();
        });
    },

    checkProdCode() {
      if (this.prodCode === "") {
        alert("상품코드를 입력해주세요.");
        return;
      }

      const param = {
        prodCode: this.prodCode,
      };

      this.$axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "api/product/checkProdCode",
          param
        )
        .then((response) => {
          // 실제 API를 요청한다/
          console.log(response.data.result);

          if (response.data.result.cnt === 0) {
            alert("사용가능한 상품코드입니다.");
            this.btnProdCodeCheckDisabled = true;
            this.prodCodeCheck = true;
            return;
          } else {
            alert("사용중인 상품코드입니다.\n다른 상품코드를 입력해주세요.");
            this.btnProdCodeCheckDisabled = false;
            this.prodCodeCheck = false;
            return;
          }
        });
    },

    checkOptCode() {
      if (this.prodCode === "") {
        alert("상품코드를 선택해주세요.");
        return;
      }

      if (this.optCode === "") {
        alert("옵션코드를 입력해주세요.");
        return;
      }

      const param = {
        prodCode: this.prodCode,
        optCode: this.optCode,
      };

      this.$axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "api/product/checkOptCode",
          param
        )
        .then((response) => {
          // 실제 API를 요청한다/
          console.log(response.data.result);

          if (response.data.result.cnt === 0) {
            alert("사용가능한 옵션코드입니다.");
            this.btnOptCodeCheckDisabled = true;
            this.optCodeCheck = true;
            return;
          } else {
            alert("사용중인 옵션코드입니다.\n다른 옵션코드를 입력해주세요.");
            this.btnOptCodeCheckDisabled = false;
            this.optCodeCheck = false;
            return;
          }
        });
    },

    checkGygOptionId() {
      if (this.gygOptionId === "") {
        alert("GYG Option ID를 입력해주세요.");
        return;
      }

      const param = {
        gygOptionId: this.gygOptionId,
      };

      this.$axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "api/product/checkGygOPtionId",
          param
        )
        .then((response) => {
          // 실제 API를 요청한다/
          console.log(response.data.result);

          if (response.data.result.cnt === 0) {
            alert("사용가능한 GYG Option ID입니다.");
            this.btnGygOptionIdCheckDisabled = true;
            this.gygOptionIdCheck = true;
            return;
          } else {
            alert(
              "사용중인 GYG Option ID코드입니다.\n다른 GYG Option ID를 입력해주세요."
            );
            this.btnGygOptionIdCheckDisabled = false;
            this.gygOptionCheck = false;
            return;
          }
        });
    },

    useFlatformFormatter(cell) {
      var content = document.createElement("div");
      content.classList.add("platform-wrap");
      const platform = cell.getValue();
      // let htmlUsePlatform = ""
      platform.forEach((item) => {
        let bClass = "badge-secondary";
        if (item === "CAFE24") {
          bClass = "badge-info";
        } else if (item === "GYG") {
          bClass = "badge-success";
        } else if (item === "USIMSTORE") {
          bClass = "badge-primary";
        }
        content.innerHTML += `<span class="platform-item badge ${bClass}">${item.substr(
          0,
          1
        )}</span>`;
      });

      return content;
    },

    cntFormatter(cell, formatterParams) {
      const content = document.createElement("span");
      const data = cell.getRow().getData();

      const is_opt = data.is_opt;

      const prod_type = data.prod_type;

      if (prod_type == "ESIM") {
        if (formatterParams == "ready") {
          if (is_opt != "0") {
            content.innerHTML = "";
          } else {
            const total = data.ready + data.imminent;
            content.innerHTML = total + "(" + data.imminent + ")";
          }
        } else if (formatterParams == "shipped") {
          const total = data.shipped + data.activated;

          content.innerHTML = total;
        } else if (formatterParams == "expired") {
          if (is_opt != "0") {
            content.innerHTML = "";
          } else {
            content.innerHTML = data.expired;
          }
        } else if (formatterParams == "canceled") {
          if (is_opt != "0") {
            content.innerHTML = "";
          } else {
            content.innerHTML = data.canceled;
          }
        }
      } else {
        content.innerHTML = "";
      }

      return content;
    },

    prodCodeClickHandeler(e, cell) {
      const data = cell.getRow().getData();
      const no = data.no;

      const idx = this.productList.findIndex((x) => x.no == no);

      this.showProductModal(true, idx);
    },

    addPartnerMailList(newTag) {
      this.partnerMailList.push(newTag);
    },

    usimVoucherHeaderLogoHandleFileUpload(event) {
      const file = event.target.files[0];

      const validExtensions = ["jpg", "jpeg", "png", "gif", "webp"];

      if (file) {
        const fileExtension = file.name.split(".").pop().toLowerCase();

        if (validExtensions.includes(fileExtension)) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            this.usimVoucherHeaderLogoBase64 = reader.result;
            this.usimVoucherHeaderLogoExtension = fileExtension;
          };
          reader.onerror = (error) => {
            console.error("Error reading file:", error);
            // this.message = 'Error reading file';
          };
        } else {
          alert("이미지 파일을 선택해주세요.");
        }
      } else {
        console.error("No file selected");
      }
    },

    makeUsimVoucherHeaderLogoFileName() {
      const fileName = this.optCode
        ? this.prodCode + "-" + this.optCode
        : this.prodCode;
      return fileName + "." + this.usimVoucherHeaderLogoExtension;
    },

    removeUsimVoucherHeaderLogo() {
      this.usimVoucherHeaderLogo = "";
    },
  },
};
</script>
<style lang="scss">
.platform-wrap:deep(.platform-item) {
  :first {
    margin-left: 0;
  }
  margin-left: 5px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
