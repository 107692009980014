<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">[UsimStore] 주문내역관리</h5>
                <p class="mb-0 text-sm"></p>
              </div>
              <!-- <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <soft-button
                    color="primary"
                    :disabled="auth == 2"
                    @click="showProductModal(false)"
                    >+&nbsp; 상품코드 등록</soft-button
                  >
                </div>
              </div> -->
            </div>
            <div class="">
              <form class="row g-3 align-items-end">
                <div class="col-2">
                  <div class="form-group mb-0">
                    <label>상품유형</label>
                    <select
                      id="admin-auth"
                      v-model="searchItemType"
                      class="form-control"
                    >
                      <option value="">전체</option>
                      <option value="ESIM">ESIM</option>
                      <option value="USIM">USIM</option>
                      <option value="WIFI">WIFI</option>
                    </select>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group mb-0">
                    <label>주문일</label>
                    <div class="input-group mb-0">
                      <flat-picker
                        ref="searchDate"
                        v-model="searchOrderDateRange"
                        :config="{
                          allowInput: true,
                          mode: 'range',
                          parseDate: true,
                        }"
                        class="form-control datepicker"
                        @on-open="focus"
                        @on-close="blur"
                        @on-change="onDateChange"
                      >
                      </flat-picker>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-12">
                  <div class="form-group mb-0">
                    <label>사용플랫폼</label>
                    <div class="input-group mb-0">
                      <VueMultiselect
                        v-model="searchUsePlatform"
                        :options="optionsUsePlatform"
                        :multiple="true"
                        :taggable="true"
                      >
                      </VueMultiselect>
                    </div>
                  </div>
                </div> -->
                <div class="col-3">
                  <div class="form-group mb-0">
                    <label>주문번호</label>
                    <div class="input-group mb-0">
                      <input
                        v-model="searchOdId"
                        type="text"
                        class="form-control"
                        placeholder="주문번호"
                        aria-label="주문번호"
                      />
                    </div>
                  </div>
                </div>
                <!-- <div class="col-12">
                  <div class="form-group mb-0">
                    <label>옵션코드</label>
                    <div class="input-group mb-0">
                      <input
                        v-model="searchOptCode"
                        type="text"
                        class="form-control"
                        placeholder="옵션코드"
                        aria-label="옵션코드"
                      />
                    </div>
                  </div>
                </div> -->
                <!-- <div class="col-12">
                  <div class="form-group mb-0">
                    <label>지역</label>
                    <select
                      id="admin-auth"
                      v-model="searchRegion"
                      class="form-control"
                    >
                      <option value="">전체</option>
                      <option value="미국">미국</option>
                      <option value="유럽">유럽</option>
                      <option value="일본">일본</option>
                      <option value="중화권">중화권</option>
                      <option value="동남아">동남아</option>
                      <option value="한국">한국</option>
                      <option value="아시아 기타">아시아 기타</option>
                    </select>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>공급처</label>
                    <select
                      id="admin-auth"
                      v-model="searchSupplier"
                      class="form-control"
                    >
                      <option value="">전체</option>
                      <option
                        v-for="item of supplierList"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.suppler_name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>상품명</label>
                    <div class="input-group mb-0">
                      <input
                        v-model="searchProdName"
                        type="text"
                        class="form-control"
                        placeholder="상품명"
                        aria-label="상품명"
                      />
                    </div>
                  </div>
                </div> -->
                <div class="col-3">
                  <button
                    type="button"
                    class="btn btn-primary mb-0"
                    @click="getOrderList()"
                  >
                    조회
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary mb-0 ms-1"
                    @click="searchInit()"
                  >
                    <span class="btn-inner--icon"
                      ><i class="fas fa-sync-alt"></i
                    ></span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div ref="table"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { TabulatorFull as Tabulator } from "tabulator-tables";

export default {
  name: "UsimStoreOrderList",
  components: {
    flatPicker,
  },
  data() {
    return {
      searchItemType: "",
      searchOrderDateRange: null,
      searchOdId: "n2023100878412091",

      modalModfy: false, //
      orderList: [],

      auth: 0,

      tabulator: null, //variable to hold your table
    };
  },
  computed: {},
  watch: {},
  mounted() {
    dayjs.extend(customParseFormat);
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const curDayJs = dayjs("2023-10-15", "YYYY-MM-DD");

    this.$refs.searchDate.fp.setDate([
      curDayJs.subtract(30, "day").toDate(),
      curDayJs.toDate(),
    ]);

    this.searchOrderDateRange =
      curDayJs.subtract(30, "day").format("YYYY-MM-DD") +
      " to " +
      curDayJs.format("YYYY-MM-DD");

    //instantiate Tabulator when element is mounted
    this.tabulator = new Tabulator(this.$refs.table, {
      data: this.productList, //link data to table
      reactiveData: true, //enable data reactivity
      renderHorizontal: "virtual",
      pagination: "local",
      paginationSize: 50,
      paginationSizeSelector: [25, 50, 100],
      paginationCounter: "rows",
      height: "100%",
      columns: [
        { title: "순번", formatter: "rownum", width: 80, headerSort: false },
        { title: "주문번호", field: "od_id", width: 250 },
        { title: "주문상태", field: "od_status" },
        { title: "주문자", field: "od_name" },
        { title: "연락처", field: "od_hp" },
        { title: "주문금액", field: "od_cart_price" },
        { title: "배송방법", field: "od_delivery_title" },
        { title: "결제수단", field: "od_settle_case" },
        { title: "구분/제휴", field: "rownum" },
        { title: "주문일시", field: "od_time" },
        {
          title: "알림톡",
          formatter: this.columnFormatterHandeler,
          formatterParams: "alimtalk",
          cellClick: this.alimtalkClickHandeler,
          vertAlign: "middle",
          hozAlign: "center",
          headerSort: false,
        },
        {
          title: "바우처",
          formatter: this.columnFormatterHandeler,
          formatterParams: "voucher",
          cellClick: this.voucherClickHandeler,
          vertAlign: "middle",
          hozAlign: "center",
          headerSort: false,
        },
      ], //define table columns
      rowFormatter: function (row) {
        //create and style holder elements
        var holderEl = document.createElement("div");
        var tableEl = document.createElement("div");

        holderEl.style.boxSizing = "border-box";
        holderEl.style.padding = "10px 30px 10px 10px";
        holderEl.style.borderTop = "1px solid #333";
        holderEl.style.borderBotom = "1px solid #333";

        tableEl.style.border = "1px solid #333";

        holderEl.appendChild(tableEl);

        row.getElement().appendChild(holderEl);

        new Tabulator(tableEl, {
          layout: "fitColumns",
          data: row.getData().od_item_info,
          headerVisible: false,
          columns: [{ title: "상세", field: "value", headerSort: false }],
        });
      },
    });
    // const thisObj = this;
    // this.tabulator.on("rowClick", function (e, row) {
    //   const data = row.getData();
    //   const st_id = data.st_id;

    //   // console.log("rowClick data");
    //   // console.log(data.st_id);

    //   const idx = thisObj.productList.findIndex((x) => x.st_id == st_id);

    //   thisObj.showProductModal(true, idx);
    // });
    const userInfo = this.$cookies.get("userInfo");
    this.auth = userInfo.auth;

    this.getOrderList();
  },
  methods: {
    searchInit() {
      this.searchItemType = "";
      this.searchOdId = "";

      const curDayJs = dayjs("2023-10-15", "YYYY-MM-DD");

      this.$refs.searchDate.fp.setDate([
        curDayJs.subtract(30, "day").toDate(),
        curDayJs.toDate(),
      ]);

      this.searchOrderDateRange =
        curDayJs.subtract(30, "day").format("YYYY-MM-DD") +
        " to " +
        curDayJs.format("YYYY-MM-DD");
    },
    getOrderList() {
      // this.productDataTable.clear();
      // this.productDataTable.data = [];

      this.orderList = [];

      const aryRangeDate = this.searchOrderDateRange
        ? this.searchOrderDateRange.split(" to ")
        : [];

      const param = {
        searchItemType: this.searchItemType,
        searchOdId: this.searchOdId,
      };

      if (aryRangeDate.length == 1) {
        param.searchOrderDateStart = dayjs(
          this.searchOrderDateRange,
          "YYYY-MM-DD"
        )
          .startOf("date")
          .format("YYYY-MM-DD HH:mm:ss");
        param.searchOrderDateEnd = dayjs(
          this.searchOrderDateRange,
          "YYYY-MM-DD"
        )
          .endOf("date")
          .format("YYYY-MM-DD HH:mm:ss");
      } else if (aryRangeDate.length == 2) {
        param.searchOrderDateStart = dayjs(aryRangeDate[0], "YYYY-MM-DD")
          .startOf("date")
          .format("YYYY-MM-DD HH:mm:ss");
        param.searchOrderDateEnd = dayjs(aryRangeDate[1], "YYYY-MM-DD")
          .endOf("date")
          .format("YYYY-MM-DD HH:mm:ss");
      }

      console.log(aryRangeDate.length);

      this.$axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "api/usimstore/getOrderList",
          param
        )
        .then((response) => {
          // 실제 API를 요청한다/
          console.log(response.data.result);

          response.data.result.forEach((item) => {
            const checkOrder = this.orderList.findIndex(
              (e) => e.od_id === item.od_id
            );

            const odItemInfoString =
              "[" + item.ct_od_id + "] " + item.it_id_opt;

            if (checkOrder == -1) {
              this.orderList.push({
                od_id: item.od_id,
                od_status: item.od_status,
                od_name: item.od_name,
                od_hp: item.od_hp,
                od_cart_price: item.od_cart_price,
                od_delivery_title: item.od_delivery_title,
                od_settle_case: item.od_settle_case,
                od_time: item.od_time,
                es_status: item.es_status,
                es_exists: item.item_type == "ESIM" ? true : false,
                od_item_info: [
                  {
                    ct_od_id: item.ct_od_id,
                    it_id_opt: item.it_id_opt,
                    value: odItemInfoString,
                  },
                ],
              });
            } else {
              const checkOrderItem = this.orderList[
                checkOrder
              ].od_item_info.findIndex((e) => e.ct_od_id === item.ct_od_id);
              if (checkOrderItem == -1) {
                if (item.item_type == "ESIM") {
                  this.orderList[checkOrder].es_exists = true;
                }
                this.orderList[checkOrder].od_item_info.push({
                  ct_od_id: item.ct_od_id,
                  it_id_opt: item.it_id_opt,
                  value: odItemInfoString,
                });
              }
            }
          });

          this.tabulator.setData(this.orderList);
        });
    },

    columnFormatterHandeler(cell, formatterParams) {
      const content = document.createElement("span");
      const data = cell.getRow().getData();

      if (formatterParams == "alimtalk") {
        if (data.es_exists && data.es_status == "C") {
          content.innerHTML = `<i class="fas fa-phone cursor-hand click-class" ></i>`;
        } else {
          content.innerHTML = "";
        }
      } else if (formatterParams == "voucher") {
        if (data.es_exists && data.es_status == "C") {
          content.innerHTML = `<i class="fas fa-envelope cursor-hand click-class" ></i>`;
        } else {
          content.innerHTML = "";
        }
      }

      return content;
    },

    alimtalkClickHandeler(e, cell) {
      const target = e.target;

      if (target.classList.contains("click-class")) {
        const data = cell.getRow().getData();
        console.log(data);
        // 알림톡 재전송
        this.$swal({
          title: "알림톡 재전송?",
          text: "알림톡을 재전송 하시겠습니까?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "&nbsp;&nbsp;&nbsp;예&nbsp;&nbsp;&nbsp;",
          cancelButtonText: "아니오",
        }).then((result) => {
          if (result.isConfirmed) {
            const param = {
              odId: data.od_id,
            };

            this.$axios
              .post(
                process.env.VUE_APP_BACKEND_URL +
                  "api/usimstore/resendAlimtalk",
                param
              )
              .then((response) => {
                // 실제 API를 요청한다/
                console.log(response.data.result);

                this.$swal({
                  title: "전송완료",
                  text: "알림톡 재전송이 완료되었습니다.",
                  icon: "success",
                });
              });
          }
        });
      }
    },

    voucherClickHandeler(e, cell) {
      const target = e.target;

      if (target.classList.contains("click-class")) {
        const data = cell.getRow().getData();
        console.log(data);
        // 바우처 재전송
        this.$swal({
          title: "바우처 재전송",
          text: "바우처를 재전송 하시겠습니까?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "&nbsp;&nbsp;&nbsp;예&nbsp;&nbsp;&nbsp;",
          cancelButtonText: "아니오",
        }).then((result) => {
          if (result.isConfirmed) {
            const param = {
              odId: data.od_id,
            };

            this.$axios
              .post(
                process.env.VUE_APP_BACKEND_URL + "api/usimstore/resendVoucher",
                param
              )
              .then((response) => {
                // 실제 API를 요청한다/
                console.log(response.data.result);

                this.$swal({
                  title: "전송완료",
                  text: "바우처 재전송이 완료되었습니다.",
                  icon: "success",
                });
              });
          }
        });
      }
    },
  },
};
</script>
<style lang="scss">
.platform-wrap:deep(.platform-item) {
  :first {
    margin-left: 0;
  }
  margin-left: 5px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
