<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">사용자 관리</h5>
                <p class="mb-0 text-sm">
                  
                </p>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  
                  <soft-button color="primary" full-width="true" :disabled="loginAuth!=0" @click="showAdminModal()">사용자추가</soft-button>
                </div>
              </div>
            </div>
            <div class="">
              
              <form class="row row-cols-lg-auto g-3 align-items-end">
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>아이디</label>
                    <div class="input-group mb-0">
                      <input v-model="searchId" type="text" class="form-control" placeholder="아이디" aria-label="아이디">
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>이름</label>
                    <div class="input-group mb-0">
                      <input v-model="searchName" type="text" class="form-control" placeholder="이름" aria-label="이름">
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <button type="button" class="btn btn-primary mb-0" @click="getAdminList()">조회</button>
                  <button type="button" class="btn btn-secondary mb-0 ms-1" @click="searchInit()">
                    <span class="btn-inner--icon"><i class="fas fa-sync-alt"></i></span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <table id="admin-list" class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      순번
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      아이디
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      이름
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      직책
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      권한
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      전화번호
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      이메일
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      수정
                    </th>
                  </tr>
                </thead>
                <tbody >
                  <tr v-for="(item, i) in adminList" :key="item">
                    <td class="text-center">
                      {{i+1}}
                    </td>
                    <td class="text-left">
                      {{item.id}}
                    </td>
                    <td class="text-left">
                      {{item.name}}
                    </td>
                    <td class="align-middle text-left text-sm">
                      {{item.type}}
                    </td>
                    <td class="align-middle text-left">
                      {{getAuthName(item.auth)}}
                    </td>
                    <td class="align-middle text-left">
                      {{item.hp}}
                    </td>
                    <td class="align-middle text-left" @click="test()">
                      {{item.email}}
                    </td>
                    <td class="align-middle text-left">
                      <button type="button" class="btn btn-primary mb-0" data-type="EDIT" data-index="0" :disabled="disabledEditBtn(item.no)" @click="showAdminModal(true, i)">
                        <span class="btn-inner--icon"><i class="fas fa-edit" data-type="EDIT"  data-index="0" ></i></span>
                      </button>
                      <button v-if="item.no !== 1" type="button" class="btn btn-danger mb-0 ms-1" data-type="DELETE" data-index="0" :disabled="loginAuth!=0" @click="deleteAdmin(item.no)">
                        <span class="btn-inner--icon"><i class="fas fa-trash" data-type="DELETE"  data-index="0" ></i></span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="modal-admin" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal-admin" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="card card-plain">
              <div class="card-header pb-0 text-left">
                <h3 class="font-weight-bolder text-primary text-gradient">{{modalTitle}}</h3>
              </div>
              <div class="card-body">
                <form role="form text-left" @submit.prevent>
                  <div class="form-group">
                    <label>아이디<span class="text-danger">*</span></label>
                    <div class="input-group mb-3">
                      <input v-model="id" type="text" class="form-control" placeholder="아이디" aria-label="아이디" :disabled="modalModfy">
                      <button v-if="!modalModfy" id="button-addon2" class="btn btn-outline-primary mb-0" type="button" :disabled="btnCheckIdDisabled" @click="checkId()">중복확인</button>
                    </div>
                  </div>
                  <label>이름<span class="text-danger">*</span></label>
                  <div class="input-group mb-3">
                    <input v-model="name" type="text" class="form-control" placeholder="이름" aria-label="이름">
                  </div>
                  <label>암호<span v-if="!modalModfy" class="text-danger">*</span></label>
                  <div class="input-group mb-3">
                    <input v-model="pass" type="password" class="form-control" placeholder="Password" aria-label="Password">
                  </div>
                  <label>암호확인<span v-if="!modalModfy" class="text-danger">*</span></label>
                  <div class="input-group mb-3">
                    <input v-model="passConfirm" type="password" class="form-control" placeholder="Password" aria-label="Password">
                  </div>
                  <div class="form-group">
                    <label for="admin-auth">권한<span class="text-danger">*</span></label>
                    <select id="admin-auth" v-model="auth"  class="form-control">
                      <option value="0">Administrator</option>
                      <option value="1">Product Manager</option>
                      <option value="2">Manager</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="admin-type">직책</label>
                    <input v-model="type" type="text" class="form-control" placeholder="직책" aria-label="직책">
                  </div>
                  <label>전화번호</label>
                  <div class="input-group mb-3">
                    <input v-model="hp" type="text" class="form-control" placeholder="전화번호"  aria-label="전화번호">
                  </div>
                  <label>이메일</label>
                  <div class="input-group mb-3">
                    <input v-model="email" type="email" class="form-control" placeholder="이메일" aria-label="이메일">
                  </div>
                  <div class="text-center">
                    <div class="row">
                      <div class="col-sm">
                        <soft-button color="primary" full-width="true" @click="saveAdmin()">{{btnSaveText}}</soft-button>
                      </div>
                      <div class="col-sm">
                        <soft-button color="secondary"  full-width="true" @click="modalAdmin.hide()">취소</soft-button>
                      </div>
                    </div>  
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { DataTable } from "simple-datatables";
import { Modal } from "bootstrap";
import SoftButton from "@/components/SoftButton.vue";

export default {
  name: "AdminLists",
  components: {
    SoftButton
  },
  data() {
    return {
      searchId: "",
      searchName: "",
      modalModfy: false, // 
      adminList: [],

      btnSaveText: "추가",
      modalTitle: "사용자추가",
      
      no: 0,
      id: "",
      name: "",
      pass: "",
      passConfirm: "",
      auth: 0,
      type: "",
      hp: "",
      email: "",

      idCheck: false,
      btnCheckIdDisabled: false,

      loginAuth: 0,
    }
  },
  computed: {
    getAuthName() {
      return (auth) => {
        if(auth === 0) {
          return "Administrator"
        }
        else if(auth === 1) {
          return "Product Manager"
        }
        else if(auth === 2) {
          return "Manager"
        }
      }
    },
    disabledEditBtn() {
      return (no) => {
        console.log("this.loginNo",this.loginNo)
        console.log("no",no)
        return !(this.loginAuth==0 || this.loginNo==no);
      }
    }
  },
  mounted() {
    const userInfo = this.$cookies.get("userInfo")
    this.loginAuth = userInfo.auth;
    this.loginNo = userInfo.no;

    console.log(this.loginNo)

    if (document.getElementById("admin-list")) {
    //   this.adminDataTable = new DataTable("#admin-list", {
    //     searchable: false,
    //     fixedHeight: false,
    //     perPage: 10,
    //     columns: [
    //     // Append a button to the seventh column
    //     {
    //         select: 0,
    //         render: function(data, cell, row) {
    //             return row.dataIndex + 1
    //         }
    //     },
    //     {
    //         select: 1,
    //         render: function(data) {
    //             return data
    //         }
    //     }
    // ]
    //   });

      // adminDataTable.body.addEventListener("click", e => {
      //   console.log("DataTable CLick Event ");
      //   console.log(e.target.getAttribute("data-id"));
      //   console.log(e.target.getAttribute("data-type"));
      //   if (e.target.getAttribute("data-type") === "EDIT") {

      //     const index = parseInt(e.target.getAttribute("data-index"), 10)
      //     // const row = adminDataTable.activeRows[index]
      //     // let message = [
      //     //   "This is row ",
      //     //   (row.rowIndex),
      //     //   " of ",
      //     //   adminDataTable.options.perPage,
      //     //   " rendered rows and row ",
      //     //   (index + 1),
      //     //   " of ",
      //     //   adminDataTable.data.length,
      //     //   " total rows."
      //     // ]

      //     // console.log(adminDataTable.data);

      //     // const data = [].slice.call(row.cells).map(cell => cell.data)

      //     // message = message.join("");

      //     // message = message + "\n\nThe row data is:\n" + JSON.stringify(data)

      //     // alert(message)

      //     this.showAdminModal(true, index)
      //   }
      // })

    }
    this.getAdminList()
  },
  methods: {
    searchInit() {
      this.searchId = ""
      this.searchName = "" 
    },
    showAdminModal(modify, index){
      console.debug("showAdminModal CALL")
      this.initData()

      this.modalModfy = modify;

      if(modify) {
        this.btnSaveText = "수정"
        this.modalTitle = "사용자 정보 수정"

        this.no = this.adminList[index].no
        this.id = this.adminList[index].id
        this.name = this.adminList[index].name
        this.auth = this.adminList[index].auth
        this.type = this.adminList[index].type
        this.hp = this.adminList[index].hp
        this.email = this.adminList[index].email
      }
      
      this.modalAdmin = new Modal(document.getElementById('modal-admin'))
      this.modalAdmin.show()
    },
    getAdminList() {
      // var vm = this;
      const param = {
        "id": this.searchId,
        "name" : this.searchName
      }
      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/admin/list", param).then((response) => { // 실제 API를 요청한다/
        console.log(response.data.result);

        this.adminList = response.data.result

        // this.adminDataTable.insert(response.data.result)
        // let data = this.adminList
        // let obj = {
        //     // Quickly get the headings
        //     headings: [
        //       "no",
        //       "id",
        //       "name",
        //       "type",
        //     ],

        //     // data array
        //     data: []
        // };

        // // Loop over the objects to get the values
        // for ( let i = 0; i < data.length; i++ ) {

        //     obj.data[i] = [];

        //     for (let p in data[i]) {
        //         // eslint-disable-next-line no-prototype-builtins
        //         if( data[i].hasOwnProperty(p) ) {
        //             console.log(data[i][p])
        //             const d = data[i][p]!==null?data[i][p]+"":""
        //             obj.data[i].push(d);
        //         }
        //     }
        // }

        // console.log(obj)

        // this.adminDataTable.insert(obj)
        // this.movieList = response.data.response.body.items.item; // 받아온 데이터를 movieList 배열에 넣어준다.
        // console.log('영화목록 : ' + this.movieList);
      })
    },
    initData() {

      this.btnSaveText = "추가"
      this.modalTitle = "사용자추가"

      this.no = 0
      this.id = ""
      this.name = ""
      this.pass = ""
      this.passConfirm = ""
      this.auth = -1
      this.type = ""
      this.hp = ""
      this.email = ""

      this.idCheck = false
      this.btnCheckIdDisabled = false
    },  
    checkId() {
      if(this.id === "") {
        alert("아이디를 입력해주세요.");
        return;
      }

      const param = {
        id: this.id
      }

      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/admin/checkId", param).then((response) => { // 실제 API를 요청한다/
        console.log(response.data.result);

        if(response.data.result.cnt === 0){
          alert("사용가능한 아이디입니다.");
          this.btnCheckIdDisabled = true
          this.idCheck = true
          return;
        }
        else {
          alert("사용중인 아이디입니다.\n다른 아이디를 입력해주세요.")
          this.btnCheckIdDisabled = false
          this.idCheck = false
          return;
        }
      })

    },
    saveAdmin() {
      if(this.id === "") {
        alert("아이디를 입력해주세요.");
        return;
      }

      if(!this.modalModfy && !this.idCheck) {
        alert("아이디 중복확인을 해주세요.");
        return;
      }

      if(this.name === "") {
        alert("이름을 입력해주세요.");
        return;
      }

      if(!this.modalModfy && this.pass === "") {
        alert("비밀번호를 입력해주세요.");
        return;
      }

      if(!this.modalModfy && this.pass !== this.passConfirm) {
        alert("비밀번호를 확인해주세요.");
        return;
      }

      if(this.modalModfy) {
        if(this.pass !== "") {
          if(this.pass !== this.passConfirm) {
            alert("비밀번호를 확인해주세요.");
            return;
          }
        }
      }

      if(this.auth === -1) {
        alert("권한을 선택해주세요.");
        return;
      }

      const param = {
        no: this.no,
        id: this.id,
        pass: this.pass,
        name: this.name,
        auth: this.auth,
        type: this.type,
        hp: this.hp,
        email: this.email
      }

      const modeUri = this.modalModfy?"update":"insert"
      const modeText = this.modalModfy?"수정":"등록"

      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/admin/"+modeUri, param).then((response) => { // 실제 API를 요청한다/
        console.log(response.data.result);

        alert("관리자 "+modeText+"이 완료되었습니다.");

        this.modalAdmin.hide()

        this.getAdminList()
      })


    },
    deleteAdmin(no) {

      if(!confirm("선택하신 관리자를 삭제하시겠습니까?")) {
        return;
      }

      const param = {
        no
      }

      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/admin/delete", param).then((response) => { // 실제 API를 요청한다/
        console.log(response.data.result);

        alert("관리자 삭제가 완료되었습니다.");

        this.getAdminList()
      })


    },


  },
};
</script>
