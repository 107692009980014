<template>
  <div class="container top-0 position-sticky z-index-sticky">
    
  </div>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder text-success text-gradient">
                    ESim Admin Management
                  </h3>
                  <p class="mb-0">Enter your id and password to sign in</p>
                </div>
                <div class="card-body">
                  <form role="form" class="text-start"  @submit.prevent>
                    <label>Id</label>
                    <input v-model="id" type="text" class="form-control" placeholder="id"  aria-label="id">
                    <label>Password</label>
                    <input v-model="pass" type="password" class="form-control" placeholder="password"  aria-label="password">
                    <div class="text-center">
                      <soft-button
                        class="my-4 mb-2"
                        variant="gradient"
                        color="success"
                        full-width
                        @click="login"
                        >Sign in
                      </soft-button>
                    </div>
                  </form>
                </div>
                
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8"
              >
                <div
                  class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                  :style="{
                    backgroundImage:
                      'url(' +
                      require('@/assets/img/curved-images/curved9.jpg') +
                      ')',
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <app-footer />
</template>

<script>
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftButton from "@/components/SoftButton.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";

export default {
  name: "SigninCover",
  components: {
    AppFooter,
    SoftButton,
  },
  data() {
    return {
      id: "",
      pass: "",
    }
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async login() {
      if(this.id === "") {
        alert("아이디를 입력해주세요.");
        return;
      }
      if(this.pass === "") {
        alert("비밀번호를 입력해주세요.");
        return;
      }
      const param = {
        id: this.id,
        pass: this.pass,
      }

      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/auth/login", param).then(async (response) => { // 실제 API를 요청한다/
        
        console.log(response);

        const loginResult = response.data;

        await this.$cookies.set('token',loginResult.token,"30d", "/");
        await this.$cookies.set('userInfo',loginResult.user[0],"30d", "/");

        this.$router.push("/dashboards/esim-dashboard");
      }).catch((error) => {
        console.log(error)
        alert(error.response.data.message);
      })


    },
  },
};
</script>
