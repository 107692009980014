<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">상품 재고 추가</h5>
                <p class="mb-0 text-sm">
                  
                </p>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  
                  <!-- <a
                    class="mb-0 btn btn-primary btn-sm"
                    target="_blank"
                    @click="showUploadFileModal()"
                    >+&nbsp; 재고 추가</a
                  > -->
                  <soft-button color="primary" :disabled="auth==2" @click="showUploadFileModal()">재고 추가</soft-button>
                </div>
              </div>
            </div>
            <div class="">
              
              <form class="row row-cols-lg-auto g-3 align-items-end">
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>상품코드<span class="text-red">*</span></label>
                    <div class="input-group mb-0">
                      <input v-model="searchProdCode" type="text" class="form-control" placeholder="" aria-label="">
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>상품명<span class="text-red">*</span></label>
                    <div class="input-group mb-0">
                      <input v-model="searchProdName" type="text" class="form-control" placeholder="" aria-label="">
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>담당자</label>
                    <select v-model="searchAdminNo"  class="form-control">
                      <option value="">전체</option>
                      <option v-for="(item) in adminList" :key="item" :value="item.no">{{item.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>업로드일자<span class="text-red">*</span></label>
                    <flat-picker
                      v-model="searchUploadDateRange"
                      :config="{ allowInput: true, mode: 'range', parseDate: true }"
                      class="form-control datepicker"
                      @on-open="focus"
                      @on-close="blur"
                      @on-change="onDateChange"
                    >
                    </flat-picker>
                  </div>
                </div>
                <div class="col-12">
                  <button type="button" class="btn btn-primary mb-0" @click="getUploadFileList()">조회</button>
                  <button type="button" class="btn btn-secondary mb-0 ms-1" @click="initSearchData()">
                    <span class="btn-inner--icon"><i class="fas fa-sync-alt"></i></span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <table id="esim-upload-list" class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      순번
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      상품코드
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      상품명
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      개수
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      업로드일시
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      담당자
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      업로드파일
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      상세
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      취소
                    </th>
                  </tr>
                </thead>
                <tbody >
                  <tr v-for="(item, i) in uploadFileList" :key="item">
                    <td class="text-center">
                      {{i+1}}
                    </td>
                    <td class="text-left">
                      {{item.prod_code}}
                    </td>
                    <td class="text-left">
                      {{item.prod_name}}
                    </td>
                    <td class="align-middle text-left text-sm">
                      {{item.esim_count}}
                    </td>
                    <td class="align-middle text-left">
                      {{getCreateDate(item.create_at)}}
                    </td>
                    <td class="align-middle text-left">
                      {{item.admin_name}}
                    </td>
                    <td class="align-middle text-left">
                      {{item.file_name}}
                    </td>
                    <td class="align-middle text-left">
                      <button type="button" class="btn btn-primary mb-0" data-type="DETAIL" data-index="0" @click="getUploadDetailList(i)">
                        <span class="btn-inner--icon"><i class="fas fa-info" data-type="DETAIL"  data-index="0" ></i></span>
                      </button>
                      </td>
                    <td class="align-middle text-left">
                      <button type="button" class="btn btn-danger mb-0 ms-1" data-type="DELETE" data-index="0" :disabled="auth==2" @click="checkUnabailableCount(item)">
                        <span class="btn-inner--icon"><i class="fas fa-trash" data-type="DELETE"  data-index="0" ></i></span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="modal-upload-file" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal-esim-upload" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="card card-plain">
              <div class="card-header pb-0 text-left">
                <h3 class="font-weight-bolder text-primary text-gradient">재고추가</h3>
              </div>
              <div class="card-body">
                <form role="form text-left" @submit.prevent>
                  <div class="text-end">
                    <soft-button color="success" @click="excelDownloadTemplate()">템플릿 다운로드</soft-button>
                  </div>
                  <h5>추가할 재고 정보가 정리된 파일을 선택하세요.</h5>
                  <div class="form-group">
                    <div class="input-group mb-3">
                      <input ref="refUploadFile" type="file" class="form-control" placeholder="파일선택" aria-label="파일선택" @change="readFile" @click="initInputfile()">
                    </div>
                  </div>
                  <div v-show="(excelData.length == 0)" class="border text-center p-2 mb-3">
                    업로드할 파일을 선택해주세요.
                  </div>
                  <div v-show="(excelData.length > 1)" class="table-responsive">
                    <div>
                      <button type="button" class="btn btn-success btn-sm">
                        <span>SUCCESS</span>
                        <span class="badge badge-secondary">{{importSuccessCnt}}</span>
                      </button>
                      <button type="button" class="btn btn-danger btn-sm ms-3">
                        <span>FAIL</span>
                        <span class="badge badge-secondary">{{importFailCnt}}</span>
                      </button>
                    </div>
                    <table id="upload-file-list" class="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th
                            class="text-secondary text-xxs font-weight-bolder opacity-7"
                          >
                            순번
                          </th>
                          <th
                            class="text-secondary text-xxs font-weight-bolder opacity-7"
                          >
                            SIM 일련번호
                          </th>
                          <th
                            class="text-secondary text-xxs font-weight-bolder opacity-7"
                          >
                            활성화 코드
                          </th>
                          <th
                            class="text-secondary text-xxs font-weight-bolder opacity-7"
                          >
                            만료일자
                          </th>
                          <th
                            class="text-secondary text-xxs font-weight-bolder opacity-7"
                          >
                            상태
                          </th>
                        </tr>
                      </thead>
                      <!-- <tbody >
                        <tr v-for="(item, i) in excelData" :key="item">
                          <td class="text-center">
                            {{i+1}}
                          </td>
                          <td class="text-left">
                            {{item.simno}}
                          </td>
                          <td class="text-left">
                            {{item.act_code}}
                          </td>
                          <td class="align-middle text-left text-sm">
                            {{item.esim_cnt}}
                          </td>
                          <td class="align-middle text-left">
                            {{item.expiry_date}}
                          </td>
                          <td class="align-middle text-left">
                            {{item.upload_state}}
                          </td>
                        </tr>
                      </tbody> -->
                    </table>
                  </div>
                  <div class="text-center">
                    <div class="row">
                      <div class="col-sm">
                        <soft-button color="primary" full-width="true" :disabled="btnUploadFileDisabled" @click="saveUploadFile()">업로드</soft-button>
                      </div>
                      <div class="col-sm">
                        <soft-button color="secondary"  full-width="true" @click="modalUploadFile.hide()">취소</soft-button>
                      </div>
                    </div>  
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="modal-upload-detail" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal-esim-upload-detail" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="card card-plain">
              <div class="card-header pb-0 text-left">
                <h3 class="font-weight-bolder text-primary text-gradient">재고추가 상세내역</h3>
              </div>
              <div class="card-body">
                <form class="row g-3 align-items-end">
                  <div class="col-6">
                    <div class="form-group mb-0">
                      <label>상품코드<span class="text-red">*</span></label>
                      <div class="input-group mb-0">
                        <input v-model="detailProdCode" type="text" class="form-control" placeholder="" aria-label="" disabled>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group mb-0">
                      <label>업로드 일시</label>
                      <input v-model="detailCreateAt" type="text" class="form-control" placeholder="" aria-label="" disabled>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group mb-0">
                      <label>상품명<span class="text-red">*</span></label>
                      <div class="input-group mb-0">
                        <input v-model="detailProdName" type="text" class="form-control" placeholder="" aria-label="" disabled>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group mb-0">
                      <label>업로드 개수</label>
                      <input v-model="detailEsimCount" type="text" class="form-control" placeholder="" aria-label="" disabled>
                    </div>
                  </div>
                </form>
                <form role="form text-left" @submit.prevent>
                  <div class="table-responsive">
                    <table id="upload-detail-list" class="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th
                            class="text-secondary text-xxs font-weight-bolder opacity-7"
                          >
                            순번
                          </th>
                          <th
                            class="text-secondary text-xxs font-weight-bolder opacity-7"
                          >
                            SIM 일련번호
                          </th>
                          <th
                            class="text-secondary text-xxs font-weight-bolder opacity-7"
                          >
                            활성화 코드
                          </th>
                          <th
                            class="text-secondary text-xxs font-weight-bolder opacity-7"
                          >
                            만료일자
                          </th>
                          <th
                            class="text-secondary text-xxs font-weight-bolder opacity-7"
                          >
                            상태
                          </th>
                        </tr>
                      </thead>
                      <!-- <tbody >
                        <tr v-for="(item, i) in excelData" :key="item">
                          <td class="text-center">
                            {{i+1}}
                          </td>
                          <td class="text-left">
                            {{item.simno}}
                          </td>
                          <td class="text-left">
                            {{item.act_code}}
                          </td>
                          <td class="align-middle text-left text-sm">
                            {{item.esim_cnt}}
                          </td>
                          <td class="align-middle text-left">
                            {{item.expiry_date}}
                          </td>
                          <td class="align-middle text-left">
                            {{item.upload_state}}
                          </td>
                        </tr>
                      </tbody> -->
                    </table>
                  </div>
                  <div class="text-center">
                    <div class="row">
                      <div class="col-sm">
                        
                      </div>
                      <div class="col-sm">
                        <soft-button color="secondary"  full-width="true" @click="modalUploadDetail.hide()">닫기</soft-button>
                      </div>
                    </div>  
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import { Modal } from "bootstrap";
import SoftButton from "@/components/SoftButton.vue";

import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import * as XLSX from "xlsx";

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'


export default {
  name: "EimStock",
  components: {
    SoftButton,
    flatPicker,
  },
  data() {
    return {
      searchProdCode: "",
      searchProdName: "",
      searchAdminNo: "",

      uploadFileList: [],
      uploadDetailList: [],

      excelData: [],
      
      adminList: [],

      uploadFile: null,

      uploadFileDataTable: null,
      uploadDetailDataTable: null,

      uploadEsimCnt: 0,

      excelFile: null,

      btnUploadFileDisabled: false,

      detailProdCode: "",
      detailProdName: "",
      detailCreateAt: "",
      detailEsimCount: "0",

      importSuccessCnt: 0,
      importFailCnt: 0,

      searchUploadDateRange: null,

      auth: 0,
    }
  },
  computed: {
    getCreateDate() {
      return (date) => {
        return dayjs(date).format('YYYY-MM-DD HH:mm')
      }
    },
  },
  mounted() {
    const userInfo = this.$cookies.get("userInfo")
    this.auth = userInfo.auth;

    if (document.getElementById("upload-file-list")) {
      this.uploadFileDataTable = new DataTable("#upload-file-list", {
        searchable: false,
        fixedHeight: false,
        perPage: 10
      });

    }

    if (document.getElementById("upload-detail-list")) {
      this.uploadDetailDataTable = new DataTable("#upload-detail-list", {
        searchable: false,
        fixedHeight: false,
        perPage: 10
      });

    }

    this.getAdminList()
    this.getUploadFileList()
  },
  methods: {
    onChange(event) {
      this.uploadFile = event.target.files ? event.target.files[0] : null;
    },
    test() {
      console.debug("test CALL");
    },
    initSearchData(){
      this.searchProdCode = ""
      this.searchProdName = ""
      this.searchAdminNo = ""
      this.searchUploadDateRange = null
      
    },
    initInputfile() {
      this.$refs.refUploadFile.value = null
      this.uploadFileDataTable.clear()
      this.uploadFileDataTable.data = []
    },
    showUploadFileModal(){
      console.debug("showUploadFileModal CALL")
      this.initUploadFileData()

      this.$refs.refUploadFile.value = null
      this.uploadFileDataTable.clear()
      this.uploadFileDataTable.data = []

      this.modalUploadFile = new Modal(document.getElementById('modal-upload-file'))
      this.modalUploadFile.show()
    },
    showUploadDetailModal(){
      console.debug("showUploadDetailModal CALL")
      
      this.modalUploadDetail = new Modal(document.getElementById('modal-upload-detail'))
      this.modalUploadDetail.show()

      // this.uploaddetailDataTable.clear()
      this.uploadDetailDataTable.data = []
    },
    getUploadFileList() {

      const aryRangeDate = this.searchUploadDateRange ? this.searchUploadDateRange.split(" to ") : []

      console.log(aryRangeDate)
      // var vm = this;
      const param = {
        "searchProdCode": this.searchProdCode,
        "searchProdName" : this.searchProdName,
        "searchAdminNo" : this.searchAdminNo,
      }

      if(aryRangeDate.length> 0) {
        param.startUploadDate = dayjs(aryRangeDate[0],"YYYY-MM-DD").startOf("date").format("YYYY-MM-DD HH:mm:ss")
        param.endUploadDate = dayjs(aryRangeDate[1],"YYYY-MM-DD").endOf("date").format("YYYY-MM-DD HH:mm:ss")
      }

      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/uploadfile/list", param).then((response) => { // 실제 API를 요청한다/
        console.log(response.data.result);

        this.uploadFileList = response.data.result
        // this.movieList = response.data.response.body.items.item; // 받아온 데이터를 movieList 배열에 넣어준다.
        // console.log('영화목록 : ' + this.movieList);
      })
    },

    getUploadDetailList(idx) {
      const searchProdNo = this.uploadFileList[idx].no
      // var vm = this;
      const param = {
        "no": searchProdNo
      }
      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/uploadfile/detailList", param).then((response) => { // 실제 API를 요청한다/
        console.log(response.data.result);

        this.uploadDetailList = [];

        this.detailProdCode = response.data.result[0].prod_code
        this.detailProdName = response.data.result[0].prod_name
        this.detailCreateAt = dayjs(response.data.result[0].create_at).format("YYYY-MM-DD HH:mm:ss")
        this.detailEsimCount = response.data.result[0].esim_count

        response.data.result.forEach((item, index) => {
          const MSISDN = item.msisdn
          const ICCID = item.iccid
          const IMSI = item.imsi
          const ACTIVATION_CODE = item.act_code
          const EXPIRE_DATE = item.expire_date
          const STATUS = item.status

          let statusMsg = "";

          if(STATUS == "READY") {
            statusMsg = "대기"
          }
          else if(STATUS === "IMMINENT") {
            statusMsg = "만료임박"
          }
          else if(STATUS === "SHIPPED") {
            statusMsg = "출고"
          }
          else if(STATUS === "ACTIVATED") {
            statusMsg = "개통"
          }
          else if(STATUS === "EXPIRED") {
            statusMsg = "만료"
          }
          else if(STATUS === "CANCELED") {
            statusMsg = "취소"
          } 

          let COL1 = ""
          if(ICCID) COL1 = ICCID
          else if(IMSI) COL1 = IMSI
          else if(MSISDN) COL1 = MSISDN

          let COL2 = ACTIVATION_CODE
          let COL3 = EXPIRE_DATE
          let COL4 = statusMsg

          const detailItem = {
            IDX:index+1,
            COL1,
            COL2,
            COL3,
            COL4,
          }

          this.uploadDetailList.push(detailItem)
        })


        let data = this.uploadDetailList
        let obj = {
            // Quickly get the headings
            headings: Object.keys(data[0]),

            // data array
            data: []
        };

        // Loop over the objects to get the values
        for ( let i = 0; i < data.length; i++ ) {

            obj.data[i] = [];

            for (let p in data[i]) {
                // eslint-disable-next-line no-prototype-builtins
                if( data[i].hasOwnProperty(p) ) {
                    console.log(data[i][p])
                    const d = data[i][p]!==null?data[i][p]+"":""
                    obj.data[i].push(d);
                }
            }
        }

        console.log(obj)

        this.uploadDetailDataTable.insert(obj)

        this.showUploadDetailModal()
        // this.movieList = response.data.response.body.items.item; // 받아온 데이터를 movieList 배열에 넣어준다.
        // console.log('영화목록 : ' + this.movieList);
      })
    },
    initUploadFileData() {

      this.uploadFile = null

      this.excelData = []
    },  
    findProdCode() {
      const filterExcelValidData = this.excelData.filter((item) => {
        return item.statusCode === 0;
      });

      const param = {
        "prodCode": filterExcelValidData[0].PRODUCT_CODE
      }
      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/product/findProdCode", param).then((response) => { // 실제 API를 요청한다/
        console.log(response.data.result);

        if(response.data.result.length == 0) {
          alert("상품코드가 잘못되었습니다.\n상품코드를 확인해주세요.")
          this.btnUploadFileDisabled = true
        }
        else {
          this.btnUploadFileDisabled = false
          this.uploadProdNo= response.data.result[0].no
        }

        // vm.uploadFileList = response.data.result
        // this.movieList = response.data.response.body.items.item; // 받아온 데이터를 movieList 배열에 넣어준다.
        // console.log('영화목록 : ' + this.movieList);
      })
    },
    saveUploadFile() {
      


      const filterExcelValidData = this.excelData.filter((item) => {
        return item.statusCode === 0; 
      });

      if(filterExcelValidData.length == 0) {
        alert("업로드할 eSim 정보가 없습니다.");
        return;
      }

      this.btnUploadFileDisabled = true;

      const formData = new FormData();
      formData.append("esimCount", filterExcelValidData.length);
      formData.append("prodNo", this.uploadProdNo);
      // formData.append("variantCode", this.excelVariantCode);
      formData.append("eSimInfo", JSON.stringify(filterExcelValidData));
      formData.append("excelFile", this.excelFile);

      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/uploadFile/save", formData,{
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then((response) => { // 실제 API를 요청한다/
        console.log(response.data.result);

        alert("재고추가가 완료되었습니다.");

        this.btnUploadFileDisabled = false;

        this.modalUploadFile.hide()

        this.getUploadFileList()
      })


    },
    deleteUploadFile(no) {

      if(!confirm("선택하신 관리자를 삭제하시겠습니까?")) {
        return;
      }

      const param = {
        no
      }

      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/uploadfile/delete", param).then((response) => { // 실제 API를 요청한다/
        console.log(response.data.result);

        alert("파일 삭제가 완료되었습니다.");

        this.getUploadFileList()
      })


    },

    async readFile(event) {
      // get File object from input tag
      const file = event.target.files[0];
      const fileName = file.name;

      this.excelFileName = fileName;

      this.excelFile = file

      // declare FileReader, temp result
      const reader = new FileReader();
      let tmpResult = [];
      const vm = this;

      console.log(XLSX)

      // if you use "this", don't use "function(e) {...}"
      reader.onload = (e) => {
        this.uploadEsimCnt = 0
        this.importFailCnt = 0
        this.importSuccessCnt = 0

        let data = e.target.result;
        data = new Uint8Array(data);
        // get excel file
        
        let excelFile = XLSX.read(data, { type: "array" });
        // let excelFile2 = XLSX.read(e.target.result, { type: "base64" });

        // get prased object
        excelFile.SheetNames.forEach(function (sheetName) {
          const roa = XLSX.utils.sheet_to_json(excelFile.Sheets[sheetName]);
          console.log(roa);
          if (roa.length) tmpResult.push(roa);
        });
        console.log(tmpResult);
        if (tmpResult.length === 0) {
          vm.$awn.alert("No Data");
          vm.excelData = [];
        }
        this.result = tmpResult[0];
        this.excelJson = tmpResult[0];

        if (tmpResult[0].length === 0) {
          vm.$awn.alert("No Data");
          vm.excelData = [];
        } else {
          vm.excelData = [];

          tmpResult[0].forEach((item, index) => {
            const PRODUCT_CODE = item.ProductCode || ""
            const MSISDN = item.MSISDN || "";
            const ICCID = item.ICCID || "";
            const IMSI = item.IMSI || "";
            const ACTIVATION_CODE = item.ActivationCode || "";
            const PIN = item.PIN || "";
            const PIN2 = item.PIN2 || "";
            const PUK = item.PUK || "";
            const PUK2 = item.PUK2 || "";
            const EXPIRE_DATE = item.ExpireDate || "";
            let statusCode = 0;
            let statusMsg = "SUCCESS";

            let COL1 = ""
            if(ICCID) COL1 = ICCID
            else if(IMSI) COL1 = IMSI
            else if(MSISDN) COL1 = MSISDN

            let COL2 = ACTIVATION_CODE

            let COL3 = EXPIRE_DATE

            // let checkData = true;
            if (COL1 === "" && COL3 === "") {
              statusMsg = "requried Data missing";
              statusCode = -1;
              // checkData = false;
            }

            let checkQrCodeBol = this.checkQrCode(COL2);
            if (!checkQrCodeBol) {
              statusMsg = "invalid QR Code";
              statusCode = -2;
              // checkData = false;
            }

            let checkExpiredDateBol = this.checkExpiredDate(EXPIRE_DATE);
            if (!checkExpiredDateBol) {
              statusMsg = "invalid Expired Date";
              statusCode = -3;
              // checkData = false;
            }

            let COL4 = statusMsg

            // if(checkData) {
            const excelItem = {
              no: index + 1,
              COL1,
              COL2,
              COL3,
              COL4,
              PRODUCT_CODE,
              MSISDN,
              ICCID,
              IMSI,
              ACTIVATION_CODE,
              PIN,
              PIN2,
              PUK,
              PUK2,
              EXPIRE_DATE,
              statusCode,
              statusMsg,
            };

            console.log(excelItem)

            vm.excelData.push(excelItem);

            // }
          });

          if (this.excelData.length > 0) {
            const checkData = {
              checkActivateCode: [],
            };

            this.excelData.forEach((item) => {
              checkData.checkActivateCode.push(item.ACTIVATION_CODE);
            });

            this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/uploadFile/checkActivateCode", checkData).then((response) => {
              console.log(response);
              const existsActivateCodeList = response.data.result;
              existsActivateCodeList.forEach((item) => {
                this.excelData.forEach((excelItem) => {
                  if (item.act_code === excelItem.ACTIVATION_CODE) {
                    excelItem.statusMsg = "exists Activate Code";
                    excelItem.statusCode = -4;
                    excelItem.COL4 = "exists Activate Code";
                  }
                });
              });

              let data = this.excelData
              let obj = {
                  // Quickly get the headings
                  headings: Object.keys(data[0]),

                  // data array
                  data: []
              };

              // Loop over the objects to get the values
              for ( let i = 0; i < data.length; i++ ) {

                if(data[i].statusCode == 0) {
                    this.uploadEsimCnt++
                    this.importSuccessCnt++
                  }
                  else {
                    this.importFailCnt++
                  }

                  obj.data[i] = [];

                  for (let p in data[i]) {
                      // eslint-disable-next-line no-prototype-builtins
                      if( data[i].hasOwnProperty(p) ) {
                          console.log(data[i][p])
                          const d = data[i][p]!==null?data[i][p]+"":""
                          obj.data[i].push(d);
                      }
                  }
              }

              console.log(obj)

              this.uploadFileDataTable.insert(obj)
              // this.esimList = res.data.result
            }).catch((error) => {
              console.log(error)
              alert(error.response.data.message);
            });
            
            
          }

          // vm.excelFile = null

          this.findProdCode()
          
        }

        // console.log(this.result)
        // console.log(this.$awn)

        console.log("@@")
        console.log(this.excelData)
      };
      reader.readAsArrayBuffer(file);
    },

    // QR Code Pattern Check
    checkQrCode(qrCode) {
      // const match = false;
      const regx = /LPA:1\$[\s\S]+\$[\s\S]+/;
      return regx.test(qrCode);
    },

    checkExpiredDate(dateString) {
      console.log("checkExpiredDate CALL")
      console.log(dateString)
      dayjs.extend(customParseFormat)
      const checkDate =  dayjs(dateString, 'YYYY-MM-DD', true).isValid()
      console.log(checkDate)
      return checkDate;
    },

    checkActivateCode() {
      console.log("checkActivateCode CALL");
      const data = {
        checkActivateCode: [],
      };

      this.excelData.forEach((item) => {
        data.checkActivateCode.push(item.ACTIVATION_CODE);
      });

      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/uploadFile/checkActivateCode", data).then((response) => {
        console.log(response);
        const existsActivateCodeList = response.data.result;
        existsActivateCodeList.forEach((item) => {
          this.excelData.forEach((excelItem) => {
            if (item.act_code === excelItem.ACTIVATION_CODE) {
              excelItem.statusMsg = "exists Activate Code";
              excelItem.statusCode = -4;
            }
          });
        });
        // this.esimList = res.data.result
      });
    },

    excelDownloadTemplate() {
      this.$axios.get(process.env.VUE_APP_BACKEND_URL+"api/uploadFile/excelDownloadTemplate", {
          responseType: "blob", // 가장 중요함
        }).then((response) => { // 실제 API를 요청한다/
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: response.headers["content-type"],
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "eSim_excel_common_template.xlsx");
          document.body.appendChild(link);
          link.click();
      })
    },

    checkUnabailableCount(item) {
      // var vm = this;
      const param = {
        no: item.no,
        prodNo: item.prod_no,
      }
      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/uploadfile/checkAvailableDelete", param).then((response) => { // 실제 API를 요청한다/
        console.log(response.data.result);

        const data = response.data.result[0]

        if(data.unabailable_count == 0) {
          if(!confirm("선택하신 상품재고를 삭제하시겠습니까?")) {
            return;
          }

          this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/uploadfile/delete", param).then((response) => { // 실제 API를 요청한다/
            console.log(response.data.result);

            alert("상품재고 삭제가 완료되었습니다.");

            this.getUploadFileList()
          })

        }
        else {
          alert("전체 '대기' 상태인경우에만 삭제가 가능합니다.")
        }

      
      })
    },

    onDateChange(selectedDates, dateStr, instance) {
        console.log(selectedDates)
        console.log(dateStr)
        console.log(instance)
    },

    getAdminList() {
      var vm = this;
      const param = {
        "id": this.searchId,
        "name" : this.searchName
      }
      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/admin/list", param).then((response) => { // 실제 API를 요청한다/
        console.log(response.data.result);

        vm.adminList = response.data.result
        // this.movieList = response.data.response.body.items.item; // 받아온 데이터를 movieList 배열에 넣어준다.
        // console.log('영화목록 : ' + this.movieList);
      })
    },

  },

  
};
</script>
